import Api from './AxiosInstance';

export default {
  registerUser(user) {
    return Api.post('api/users/register', user);
  },

  getAllUsers() {
    return Api.get('api/users');
  },

  updateUser(user) {
    return Api.put('api/users/' + user._id, user);
  },

  deleteUser(id) {
    return Api.delete('api/users/' + id);
  }
};
