<template>
  <div :style="{ width }" :title="value" class="gli-label">
    {{ value }}
  </div>
</template>

<script>
export default {
  name: 'GliLabel',
  props: {
    value: { type: String, default: '' },
    width: String
  }
};
</script>
