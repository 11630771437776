import ApiService from '@/services/ApiService';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

type MapState = {
  baseMapStyle?: Record<'sources' | 'layers' | string, any>;
  style?: Record<'sources' | 'layers' | string, any>;
};

const state: MapState = {
  baseMapStyle: undefined,
  style: undefined
};

const actions: ActionTree<MapState, any> = {
  async changeBaseMapStyle({ commit }, { baseMapStyle }) {
    const { data: style } = await ApiService.getBaseMapStyle(baseMapStyle);

    commit('changeBaseMapStyle', style);
  }
};

const mutations: MutationTree<MapState> = {
  changeBaseMapStyle(state, baseMapStyle) {
    state.baseMapStyle = baseMapStyle;
  }
};

const getters: GetterTree<MapState, any> = {
  baseMapStyle: (state) => state.baseMapStyle
};

export const map = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
