import Vue from 'vue';
import i18n from '../i18n';

export default async (message, options) => {
  return new Vue().$confirm(message, {
    buttonTrueText: i18n.t('Yes'),
    buttonFalseText: i18n.t('No'),
    color: 'error',
    icon: 'warning',
    title: i18n.t('Warning'),
    width: 450,
    ...options
  });
};
