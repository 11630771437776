import Vue from 'vue';
import Vuex from 'vuex';

import { alert } from './alert.module';
import { account } from './account.module';
import { users } from './users.module';
import { app } from './app.module';
import { site } from './site.module.ts';
import { venue } from './venue.module.ts';
import poi from './poi.module';
import annotation from './annotation.module';
import feature from './feature.module';
import { image } from './image.module';
import { customImage } from './customImage.module';
import { level } from './level.module';
import elevator from './elevator.module';
import stairs from './stairs.module';
import entryPoint from './entryPoint.module';
import referenceNode from './referenceNode.module';
import { status } from './status.module';
import organization from './organization.module';
import routing from './routing.module';
import { history } from './history.module.ts';
import { map } from './map.module.ts';
import zone from './zone.module';
import { router } from '../router';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    alert,
    account,
    users,
    app,
    site,
    venue,
    poi,
    annotation,
    feature,
    image,
    customImage,
    level,
    elevator,
    stairs,
    entryPoint,
    referenceNode,
    status,
    organization,
    routing,
    history,
    map,
    zone
  }
});

// global error handler for all vuex actions
const original = store.dispatch;
store.dispatch = (type, ...params) => {
  // // create mutations to handle isLoading on async call

  const action = original.apply(store, [type, ...params]);

  action
    .then((result) => {
      Promise.resolve(result);
    })
    .catch((err) => {
      if (403 === err?.response?.status) {
        router.push('/');
        store.commit('alert/error', 'Access denied!');
      } else {
        store.commit('alert/error');
      }
      Promise.reject(err);
    });

  return action;
};

export { store };
