const constants = {
  PROCESS_MESSAGES: {
    CACHE: {
      REMOVE_RASTERSOURCE_URLS: 'REMOVE_RASTERSOURCE_URLS'
    }
  },

  ELEVATOR_SNAP_DISTANCE: 0.0005,
  ELEVATOR_MOVE_TYPES: {
    FREE: 'free',
    WITH_PATH: 'with_path'
  },

  EOV_PROJECTION: `+proj=somerc +lat_0=47.14439372222222 +lon_0=19.04857177777778 +k_0=0.99993
  +x_0=650000 +y_0=200000 +ellps=GRS67 +towgs84=52.17,-71.82,-14.9,0,0,0,0 +units=m +no_defs`,

  OPERATIONS: {
    SITE: {
      RENAME: 'Rename site'
    },
    LEVEL: {
      ADD: 'Add new level',
      DELETE: 'Delete level',
      DUPLICATE: 'Duplicate level',
      ORDER: 'Order levels',
      RENAME: 'Rename level',
      STREET_LEVEL: 'Change street level'
    },
    IMAGE: {
      UPLOAD: 'Upload image',
      DELETE: 'Delete image',
      MODIFY: 'Modify image'
    },
    NETWORK: {
      MODIFY: 'Modify network',
      MODIFY_PATH_CLASS: 'Modify path type',
      MODIFY_STAIRS_DIRECTION: 'Modify stairs direction',
      MODIFY_VTR: 'Modify transit rule',
      ADD_JUNCTION: 'Add new node (network)',
      ADD_ENTRY_POINT: 'Add new entry point',
      ADD_STAIRS: 'Add new stairs',
      ADD_ELEVATOR: 'Add new elevator',
      DELETE_ELEVATOR: 'Delete elevator',
      ADD_NETWORK_SEGMENT: 'Add new edge (network)',
      UPDATE_ENTRY_POINT: 'Update entry point'
    },
    FLOORPLAN: {
      MODIFY: 'Modify floorplan',
      ROTATION: 'Rotation',
      ADD_NODE: 'Add new node (floorplan)',
      ADD_EDGE: 'Add new edge (floorplan)',
      ADD_RECTANGLE: 'Add new rectangle',
      ADD_ELLIPSE: 'Add new ellipse',
      ADD_POI: 'Add new POI',
      ADD_POLYGON: 'Add new polygon',
      ADD_REFERENCE_NODE: 'Add new reference node',
      DELETE_POI: 'Delete POI',
      MODIFY_POI: 'Modify POI',
      ADD_ANNOTATION: 'Add new annotation',
      DELETE_ANNOTATION: 'Delete annotation',
      MODIFY_ANNOTATION: 'Modify annotation',
      DUPLICATE: 'Duplicate selected',
      DELETE: 'Delete',
      EDGE_TYPE: 'Change edge type',
      EDGE_THICKNESS: 'Change edge thickness',
      EXTRUSION_TOP: 'Change extrusion top',
      EXTRUSION_BASE: 'Change extrusion base',
      CHANGE_TAG: 'Change tag',
      MAJOR_AXIS: 'Change major axis',
      MINOR_AXIS: 'Change minor axis',
      BEARING: 'Change bearing',
      RECTANGLE_LENGTH: 'Change rectangle length',
      RECTANGLE_WIDTH: 'Change rectangle width'
    },
    ZONE: {
      ADD: 'Add new zone',
      MODIFY: 'Modify zone'
    }
  },

  ROLES: {
    SUPER_ADMIN: 'super_admin',
    ORG_ADMIN: 'org_admin',
    MAPPER: 'mapper',
    OPERATOR: 'operator',
    VIEWER: 'viewer'
  },

  SAMPLE: {
    SITE_ID: '60799327c41f660608781ce3',
    LEVEL_ID: '60799327c41f660608781ce4',
    ORG_ID: '60799327c41f660608781ce5'
  },

  DEFAULT_ERROR_MESSAGES: {
    FORBIDDEN: { message: 'Forbidden' },
    NOT_FOUND: { message: 'Not found' },
    BAD_REQUEST: { message: 'Bad request' },
    UNAUTHORIZED: {
      message: 'Unauthorized'
    },
    SERVER_ERROR: { message: 'Server Error' }
  },

  MAKI_ICONS: [
    'maki_aerialway',
    'maki_airfield',
    'maki_airport',
    'maki_alcohol-shop',
    'maki_american-football',
    'maki_amusement-park',
    'maki_aquarium',
    'maki_art-gallery',
    'maki_attraction',
    'maki_bakery',
    'maki_bank-JP',
    'maki_bank',
    'maki_bar',
    'maki_barrier',
    'maki_baseball',
    'maki_basketball',
    'maki_bbq',
    'maki_beach',
    'maki_beer',
    'maki_bicycle-share',
    'maki_bicycle',
    'maki_blood-bank',
    'maki_bowling-alley',
    'maki_bridge',
    'maki_building-alt1',
    'maki_building',
    'maki_bus',
    'maki_cafe',
    'maki_campsite',
    'maki_car-rental',
    'maki_car-repair',
    'maki_car',
    'maki_casino',
    'maki_castle-JP',
    'maki_castle',
    'maki_cemetery-JP',
    'maki_cemetery',
    'maki_charging-station',
    'maki_cinema',
    'maki_circle-stroked',
    'maki_circle',
    'maki_city',
    'maki_clothing-store',
    'maki_college-JP',
    'maki_college',
    'maki_commercial',
    'maki_communications-tower',
    'maki_confectionery',
    'maki_convenience',
    'maki_cricket',
    'maki_cross',
    'maki_dam',
    'maki_danger',
    'maki_defibrillator',
    'maki_dentist',
    'maki_doctor',
    'maki_dog-park',
    'maki_drinking-water',
    'maki_embassy',
    'maki_emergency-phone',
    'maki_entrance-alt1',
    'maki_entrance',
    'maki_farm',
    'maki_fast-food',
    'maki_fence',
    'maki_ferry',
    'maki_fire-station-JP',
    'maki_fire-station',
    'maki_fitness-centre',
    'maki_florist',
    'maki_fuel',
    'maki_furniture',
    'maki_gaming',
    'maki_garden-centre',
    'maki_garden',
    'maki_gift',
    'maki_globe',
    'maki_golf',
    'maki_grocery',
    'maki_hairdresser',
    'maki_harbor',
    'maki_hardware',
    'maki_heart',
    'maki_heliport',
    'maki_home',
    'maki_horse-riding',
    'maki_hospital-JP',
    'maki_hospital',
    'maki_ice-cream',
    'maki_industry',
    'maki_information',
    'maki_jewelry-store',
    'maki_karaoke',
    'maki_landmark-JP',
    'maki_landmark',
    'maki_landuse',
    'maki_laundry',
    'maki_library',
    'maki_lighthouse',
    'maki_lodging',
    'maki_logging',
    'maki_marker-stroked',
    'maki_marker',
    'maki_mobile-phone',
    'maki_monument',
    'maki_mountain',
    'maki_museum',
    'maki_music',
    'maki_natural',
    'maki_optician',
    'maki_paint',
    'maki_park-alt1',
    'maki_park',
    'maki_parking-garage',
    'maki_parking',
    'maki_pharmacy',
    'maki_picnic-site',
    'maki_pitch',
    'maki_place-of-worship',
    'maki_playground',
    'maki_police-JP',
    'maki_police',
    'maki_post-JP',
    'maki_post',
    'maki_prison',
    'maki_rail-light',
    'maki_rail-metro',
    'maki_rail',
    'maki_ranger-station',
    'maki_recycling',
    'maki_religious-buddhist',
    'maki_religious-christian',
    'maki_religious-jewish',
    'maki_religious-muslim',
    'maki_religious-shinto',
    'maki_residential-community',
    'maki_restaurant-noodle',
    'maki_restaurant-pizza',
    'maki_restaurant-seafood',
    'maki_restaurant',
    'maki_roadblock',
    'maki_rocket',
    'maki_school-JP',
    'maki_school',
    'maki_scooter',
    'maki_shelter',
    'maki_shoe',
    'maki_shop',
    'maki_skateboard',
    'maki_skiing',
    'maki_slaughterhouse',
    'maki_slipway',
    'maki_snowmobile',
    'maki_soccer',
    'maki_square-stroked',
    'maki_square',
    'maki_stadium',
    'maki_star-stroked',
    'maki_star',
    'maki_suitcase',
    'maki_sushi',
    'maki_swimming',
    'maki_table-tennis',
    'maki_teahouse',
    'maki_telephone',
    'maki_tennis',
    'maki_theatre',
    'maki_toilet',
    'maki_town-hall',
    'maki_town',
    'maki_triangle-stroked',
    'maki_triangle',
    'maki_veterinary',
    'maki_viewpoint',
    'maki_village',
    'maki_volcano',
    'maki_volleyball',
    'maki_warehouse',
    'maki_waste-basket',
    'maki_watch',
    'maki_water',
    'maki_waterfall',
    'maki_watermill',
    'maki_wetland',
    'maki_wheelchair',
    'maki_windmill',
    'maki_zoo'
  ],

  MAIN_MODES: {
    LEVEL: 'level',
    FLOORPLAN: 'floorplan',
    NETWORK: 'network',
    ZONE: 'zone',
    STATIC: 'static'
  },

  DEFAULT_DRAW_MODES: {
    level: 'StaticMode',
    floorplan: 'simple_select',
    network: 'simple_select',
    zone: 'simple_select',
    static: 'StaticMode'
  },

  DRAW_MODES: {
    NODE: 'draw_node',
    EDGE: 'draw_edge',
    ELLIPSE: 'draw_ellipse',
    RECTANGLE: 'draw_rectangle',
    POLYGON: 'draw_polygon',
    ROTATE: 'rotate',
    SIMPLE_SELECT: 'simple_select',
    DIRECT_SELECT: 'direct_select',
    STATIC: 'StaticMode',
    POI: 'draw_poi',
    ANNOTATION: 'draw_annotation',
    REFERENCE_NODE: 'draw_reference_node',
    ENTRY_POINT: 'entry_point',
    STAIRS: 'stairs',
    ELEVATOR: 'elevator',
    IMAGE: 'image',
    CUSTOM_IMAGE: 'custom_image',
    ZONE: 'draw_zone',
    DEFAULT: 'default' // not a real mode, indicates that DEFAULT_DRAW_MODE should be applied
  },

  FEATURE_TYPES: {
    NODE: 'node',
    EDGE: 'edge',
    JUNCTION: 'junction',
    NETWORK_SEGMENT: 'networkSegment',
    EXCHANGE_POINT: 'exchangePoint',
    FACILITY: 'facility',
    POI: 'poi',
    ANNOTATION: 'annotation',
    REFERENCE_NODE: 'reference_node',
    HIGHLIGHT_POINT: 'highlight_point',
    ROUTE_FROM: 'route_from',
    ROUTE_TO: 'route_to',
    ROUTE: 'route',
    VENUE_CONNECTION: 'venue_connection',
    ZONE: 'zone',
    MEASURE_GUIDE: 'measure_guide'
  },

  FACILITY_TYPES: {
    ELLIPSE: 'ellipse',
    RECTANGLE: 'rectangle',
    POLYGON: 'polygon'
  },

  FEATURES_TO_SAVE: {
    DRAW: 'draw'
  },

  MOUSE_BUTTON: {
    LEFT: 0, // main
    MIDDLE: 1, // auxiliary
    RIGHT: 2 // second
  },

  DEFAULT_VTR: 'NNYN',
  DEFAULT_WHEELCHAIR: true,

  VTR: {
    STAFF: 'Staff',
    GUEST: 'Guests',
    PUBLIC: 'Public',
    EMERGENCY: 'Emergency'
  },

  EDGE_TYPES: {
    WALL: 'wall',
    DOOR: 'door',
    WINDOW: 'window'
  },

  EDGE_THICKNESS: {
    THICK: 'thick',
    THIN: 'thin',
    LOGICAL: 'logical'
  },

  EXCHANGE_POINT_TYPES: {
    LEVEL_TRANSITION: 'level_transition',
    NETWORK_TRANSITION: 'network_transition',
    SITE_TRANSITION: 'site_transition'
  },

  EXCHANGE_POINT_METHODS: {
    SAME_JUNCTION: 'same_junction',
    ELEVATOR_SHAFT: 'elevator_shaft',
    VIRTUAL_PATH: 'virtual_path'
  },

  JUNCTION_TYPES: {
    NORMAL: 'normal',
    STAIRS: 'stairs',
    ELEVATOR: 'elevator',
    ENTRY_POINT: 'entry_point'
  },

  STAIRS_TYPES: {
    UPWARDS: 'upwards',
    DOWNWARDS: 'downwards',
    NOTSET: 'notset'
  },

  SNAPPING_DISTANCE_PX: 24,
  SNAPPING_ANGLE_DEGREE: 5,

  IMAGE_SIZE_LIMIT: 16777216,
  MAX_IMAGE_REFERENCE_POINTS: 2,

  CUSTOM_DRAW_EVENTS: {
    SNAPPING: 'draw.snapping',
    EDGE_DRAWING: 'draw.edge_drawing',
    MODIFICATION: 'draw.modification',
    ROTATION: 'draw.rotation',
    SPECIAL_JUNCTION_OVERWRITING: 'draw.overwriting'
  },
  PATH_CLASSES: {
    NORMAL: 'normal',
    STAIRS_UP: 'stairs_up',
    STAIRS_DOWN: 'stairs_down'
  },
  PATH_CLASS_ACTION_TYPES: {
    STAIRS: 'stairs',
    DIRECTION: 'direction'
  },
  GDAL: {
    EOV_BOUND_POLYGON: {
      type: 'Polygon',
      coordinates: [
        [
          [16.11, 45.74],
          [22.9, 45.74],
          [22.9, 48.58],
          [16.11, 48.58],
          [16.11, 45.74]
        ]
      ]
    },
    WGS84: '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs ',
    // eslint-disable-next-line max-len
    EOV: '+proj=somerc +lat_0=47.14439372222222 +lon_0=19.04857177777778 +k_0=0.99993 +x_0=650000 +y_0=200000 +ellps=GRS67 +units=m +no_defs ',
    EOV_BOUND_X_MIN: 48212.58,
    EOV_BOUND_X_MAX: 366660.88,
    EOV_BOUND_Y_MIN: 421391.21,
    EOV_BOUND_Y_MAX: 934220.63,
    WGS84_BOUND_LON: 180,
    WGS84_BOUND_LAT: 90
  },

  PUBLISH_STATUS: {
    FAILED: 'failed',
    FINISHED: 'finished',
    PUBLISHING: 'loading'
  },

  DEFAULT_POI_PROPERTIES: {
    DEFAULT_NAME: 'POI',
    DEFAULT_ICON: 'maki_marker'
  },

  DEFAULT_ANNOTATION: 'Lorem ipsum',

  EXPORT_TYPES: {
    GEOJSON: 'geojson',
    PNG: 'png',
    TIFF: 'tiff'
  },

  /** maps the colorable 'layers' with the layers that are actually used in this program  */
  COLORABLE_LAYERS_MAPPINGS: {
    'gl-draw-annotation-inactive.cold': 'annotation',
    'gl-draw-annotation-inactive.hot': 'annotation',
    'static-annotation': 'annotation',
    'gl-draw-poi-inactive.cold': 'poi-label',
    'gl-draw-poi-inactive.hot': 'poi-label',
    'static-poi': 'poi-label',
    'gl-draw-point-inactive.cold': 'node',
    'gl-draw-point-inactive.hot': 'node',
    'gl-draw-exchange-normal-inactive.cold': 'junction',
    'gl-draw-exchange-normal-inactive.hot': 'junction',
    'gl-draw-exchange-entry_point-inactive.cold': 'entry-point-base',
    'gl-draw-exchange-entry_point-inactive.hot': 'entry-point-base',
    'gl-draw-exchange-elevator-inactive.cold': 'elevator-base',
    'gl-draw-exchange-elevator-inactive.hot': 'elevator-base',
    'gl-draw-exchange-stairs-inactive.cold': 'stairs-base',
    'gl-draw-exchange-stairs-inactive.hot': 'stairs-base',
    'gl-draw-line-inactive.cold': 'network-segment',
    'gl-draw-line-inactive.hot': 'network-segment',
    'gl-draw-edge-window-thin-inactive.cold': 'window-thin',
    'gl-draw-edge-window-thin-inactive.hot': 'window-thin',
    'gl-draw-edge-window-thick-inactive.cold': 'window-thick',
    'gl-draw-edge-window-thick-inactive.hot': 'window-thick',
    'gl-draw-edge-door-thick-inactive.cold': 'door-thick',
    'gl-draw-edge-door-thick-inactive.hot': 'door-thick',
    'gl-draw-edge-door-thin-inactive.cold': 'door-thin',
    'gl-draw-edge-door-thin-inactive.hot': 'door-thin',
    'gl-draw-edge-wall-thick-inactive.cold': 'wall-thick',
    'gl-draw-edge-wall-thick-inactive.hot': 'wall-thick',
    'gl-draw-edge-wall-thin-inactive.cold': 'wall-thin',
    'gl-draw-edge-wall-thin-inactive.hot': 'wall-thin',
    'gl-draw-edge-wall-logical-inactive.cold': 'wall-logical',
    'gl-draw-edge-wall-logical-inactive.hot': 'wall-logical',
    'static-edge-wall-thick': 'wall-thick',
    'static-edge-wall-thin': 'wall-thin',
    'static-edge-door-thick': 'door-thick',
    'static-edge-door-thin': 'door-thin',
    'static-edge-window-thick': 'window-thick',
    'static-edge-window-thin': 'window-thin',
    'gl-draw-extrusion-ellipse-inactive.cold': 'ellipse-facility-extrusion',
    'gl-draw-extrusion-ellipse-inactive.hot': 'ellipse-facility-extrusion',
    'static-facility-ellipse-extrusion': 'ellipse-facility-extrusion',
    'gl-draw-extrusion-rectangle-inactive.cold': 'rectangle-facility-extrusion',
    'gl-draw-extrusion-rectangle-inactive.hot': 'rectangle-facility-extrusion',
    'static-facility-rectangle-extrusion': 'rectangle-facility-extrusion',
    'gl-draw-extrusion-polygon-inactive.cold': 'polygon-facility-extrusion',
    'gl-draw-extrusion-polygon-inactive.hot': 'polygon-facility-extrusion',
    'static-facility-polygon-extrusion': 'polygon-facility-extrusion',
    'static-edge-wall-extrusion': 'wall-extrusion',
    'gl-draw-ellipse-fill-inactive.cold': 'ellipse-facility-fill',
    'gl-draw-ellipse-fill-inactive.hot': 'ellipse-facility-fill',
    'static-facility-ellipse-fill': 'ellipse-facility-fill',
    'gl-draw-rectangle-fill-inactive.cold': 'rectangle-facility-fill',
    'gl-draw-rectangle-fill-inactive.hot': 'rectangle-facility-fill',
    'static-facility-rectangle-fill': 'rectangle-facility-fill',
    'gl-draw-polygon-fill-inactive.cold': 'polygon-facility-fill',
    'gl-draw-polygon-fill-inactive.hot': 'polygon-facility-fill',
    'static-facility-polygon-fill': 'polygon-facility-fill',
    'gl-draw-ellipse-stroke-inactive.cold': 'ellipse-facility-stroke',
    'gl-draw-ellipse-stroke-inactive.hot': 'ellipse-facility-stroke',
    'static-facility-ellipse-stroke': 'ellipse-facility-stroke',
    'gl-draw-rectangle-stroke-inactive.cold': 'rectangle-facility-stroke',
    'gl-draw-rectangle-stroke-inactive.hot': 'rectangle-facility-stroke',
    'static-facility-rectangle-stroke': 'rectangle-facility-stroke',
    'gl-draw-polygon-stroke-inactive.cold': 'polygon-facility-stroke',
    'gl-draw-polygon-stroke-inactive.hot': 'polygon-facility-stroke',
    'static-facility-polygon-stroke': 'polygon-facility-stroke',
    rooms: 'rooms'
  }
};

export default {
  ...constants,
  STYLES: {
    EXTRUSION: {
      WALL: {
        OPACITY: {
          LIGHT: 0.6,
          BRIGHT: 0.8
        }
      },
      FACILITY: {
        OPACITY: {
          LIGHT: 0.6,
          BRIGHT: 0.8
        },
        COLOR: {
          ACTIVE: '#fbb03b',
          INACTIVE: '#3bb2d0',
          STATIC: '#3bb2d0'
        }
      }
    },

    LINE: {
      COLOR: {
        ACTIVE: '#ffcc00',
        INACTIVE: '#dd6600'
      },
      OPACITY: {
        ACTIVE: 0.8,
        INACTIVE: 0.8,
        STATIC: 0.4
      },
      LINE_WIDTH: 3,
      DASH_ARRAY: [2, 1]
    },

    FACILITY: {
      FILL_COLOR: {
        ACTIVE: '#fbb03b',
        INACTIVE: '#3bb2d0'
      },
      FILL_OPACITY: {
        ACTIVE: 0.1,
        INACTIVE: 0.1,
        STATIC: 0.05
      },
      LINE_COLOR: {
        ACTIVE: '#fbb03b',
        INACTIVE: '#3bb2d0'
      },
      LINE_OPACITY: {
        ACTIVE: 1,
        INACTIVE: 1,
        STATIC: 1
      },
      LINE_WIDTH: {
        ACTIVE: 2,
        INACTIVE: 2
      },
      LINE_DASH_ARRAY: [2, 4],
      VERTEX_STROKE_COLOR: '#ffffff',
      VERTEX_STROKE_RADIUS: 8,
      VERTEX_MAIN_COLOR: '#fbb03b',
      VERTEX_MAIN_RADIUS: 5
    },

    POI: {
      TEXT_OPACITY: {
        ACTIVE: 1,
        INACTIVE: 1,
        STATIC: 0.4
      },
      ICON_OPACITY: {
        DRAW: 1,
        STATIC: 0.4
      },
      ICON_HALO_WIDTH: 2,
      ICON_HALO_COLOR: '#00ff00',
      ACTIVE_CIRCLE_RADIUS: 20,
      ACTIVE_CIRCLE_COLOR: '#ff0000',
      ACTIVE_CIRCLE_OPACTIY: 0.8,
      ACTIVE_CIRCLE_BLUR: 0.8
    },

    ZONE: {
      DEFAULT_COLOR: '#dd77dd',
      DEFAULT_FILL_OPACITY: 0.2,
      DEFAULT_LINE_WIDTH: 4,
      DEFAULT_LINE_DASH_ARRAY: [1, 2]
    },

    VENUE_EDITOR: {
      WALL: {
        WIDTH: 6,
        COLOR: '#777777',
        OPACITY: 0.5
      },
      DOOR: {
        WIDTH: 6,
        COLOR: '#999999',
        OPACITY: 0.3
      },
      NETWORK: {
        WIDTH: 3,
        COLOR: '#666666',
        OPACITY: 0.7,
        DASH_ARRAY: [2, 1]
      },
      CONNECTION_COLOR_INACTIVE: '#00cc00',
      CONNECTION_OPACITY_INACTIVE: 0.7,

      SITE_TRANSITION: {
        CIRCLE_RADIUS: {
          HIDDEN: ['interpolate', ['linear'], ['zoom'], 14, 0, 20, 9, 22, 15],
          USED: ['interpolate', ['linear'], ['zoom'], 14, 0, 20, 9, 22, 15],
          INACTIVE: ['interpolate', ['linear'], ['zoom'], 14, 0, 20, 9, 22, 15],
          ACTIVE: ['interpolate', ['linear'], ['zoom'], 14, 0, 20, 18, 22, 30]
        },
        CIRCLE_COLOR: {
          HIDDEN: '#666666',
          USED: '#00cc00',
          INACTIVE: '#333333',
          ACTIVE: '#000000'
        },
        CIRCLE_OPACITY: {
          HIDDEN: 0.3,
          USED: 0.6,
          INACTIVE: 0.6,
          ACTIVE: 0.6
        },
        CIRCLE_BLUR_ACTIVE: 0.8,
        ICON: 'mdi_door',
        ICON_SIZE: ['interpolate', ['linear'], ['zoom'], 14, 0, 20, 0.6, 22, 1],
        ICON_OPACITY: {
          HIDDEN: 0.4,
          USED: 0.8,
          INACTIVE: 0.8,
          ACTIVE: 1
        }
      }
    },

    EDGE_STYLES: {
      LINE_COLOR: {
        [constants.EDGE_TYPES.WALL]: '#a44a3f',
        [constants.EDGE_TYPES.DOOR]: '#afd275',
        [constants.EDGE_TYPES.WINDOW]: '#e7717d'
      },
      LINE_WIDTH: {
        [constants.EDGE_THICKNESS.THICK]: 8,
        [constants.EDGE_THICKNESS.THIN]: 4,
        [constants.EDGE_THICKNESS.LOGICAL]: 4
      },
      LINE_DASHARRAY: {
        [constants.EDGE_THICKNESS.THICK]: [1],
        [constants.EDGE_THICKNESS.THIN]: [1],
        [constants.EDGE_THICKNESS.LOGICAL]: [1, 1]
      }
    },

    EXCHANGE_POINT_STYLES: {
      CIRCLE_RADIUS: {
        [constants.JUNCTION_TYPES.ENTRY_POINT]: 12,
        [constants.JUNCTION_TYPES.STAIRS]: 12,
        [constants.JUNCTION_TYPES.ELEVATOR]: 12,
        [constants.JUNCTION_TYPES.NORMAL]: 6
      },
      CIRCLE_COLOR: {
        ACTIVE: '#ff0000',
        INACTIVE: '#ff6666'
      },
      CIRCLE_OPACITY: {
        ACTIVE: 0.8,
        INACTIVE: 0.8,
        STATIC: 0.4
      },
      ICON: {
        [constants.JUNCTION_TYPES.ENTRY_POINT]: 'mdi_door',
        [constants.STAIRS_TYPES.NOTSET]: 'mdi_stairs',
        [constants.STAIRS_TYPES.UPWARDS]: 'mdi_stairs-up',
        [constants.STAIRS_TYPES.DOWNWARDS]: 'mdi_stairs-down',
        [constants.JUNCTION_TYPES.ELEVATOR]: 'mdi_elevator-passenger'
      },
      ICON_OPACITY: {
        ACTIVE: 1,
        INACTIVE: 0.8,
        STATIC: 0.4
      }
    },

    IMAGE_MANIPULATION_ARROWS: {
      // eslint-disable-next-line max-len
      RESIZE: `url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23000000" stroke="%23ffffff" d="M13,21H21V13H19V17.59L6.41,5H11V3H3V11H5V6.41L17.59,19H13V21Z"></path></svg>')`,
      // eslint-disable-next-line max-len
      MOVE: `url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23000000" stroke="%23ffffff" d="M22.67,12L18.18,16.5L15.67,14L17.65,12L15.67,10.04L18.18,7.53L22.67,12M12,1.33L16.47,5.82L13.96,8.33L12,6.35L10,8.33L7.5,5.82L12,1.33M12,22.67L7.53,18.18L10.04,15.67L12,17.65L14,15.67L16.5,18.18L12,22.67M1.33,12L5.82,7.5L8.33,10L6.35,12L8.33,13.96L5.82,16.47L1.33,12M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10Z"></path></svg>')`,
      // eslint-disable-next-line max-len
      ROTATE: `url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23000000" stroke="%23ffffff" d="M12,6V9L16,5L12,1V4A8,8 0 0,0 4,12C4,13.57 4.46,15.03 5.24,16.26L6.7,14.8C6.25,13.97 6,13 6,12A6,6 0 0,1 12,6M18.76,7.74L17.3,9.2C17.74,10.04 18,11 18,12A6,6 0 0,1 12,18V15L8,19L12,23V20A8,8 0 0,0 20,12C20,10.43 19.54,8.97 18.76,7.74Z"></path></svg>')`,
      WIDTH: '24px',
      HEIGHT: '24px'
    }
  },

  EXTRUSION_FEATURES: [
    constants.FEATURE_TYPES.FACILITY,
    constants.FEATURE_TYPES.EDGE,
    constants.EDGE_TYPES.WALL
  ],

  FEATURE_GROUPS: {
    [constants.MAIN_MODES.STATIC]: {
      DRAW: [],
      BRIGHT: [
        constants.FEATURE_TYPES.ANNOTATION,
        constants.FEATURE_TYPES.EDGE,
        constants.FEATURE_TYPES.FACILITY,
        constants.FEATURE_TYPES.JUNCTION,
        constants.FEATURE_TYPES.NETWORK_SEGMENT,
        constants.FEATURE_TYPES.POI
      ],
      LIGHT: [],
      HIDDEN: [
        constants.FEATURE_TYPES.NODE,
        constants.FEATURE_TYPES.REFERENCE_NODE,
        constants.FEATURE_TYPES.ZONE
      ]
    },
    [constants.MAIN_MODES.LEVEL]: {
      DRAW: [],
      BRIGHT: [
        constants.FEATURE_TYPES.ANNOTATION,
        constants.FEATURE_TYPES.EDGE,
        constants.FEATURE_TYPES.FACILITY,
        constants.FEATURE_TYPES.JUNCTION,
        constants.FEATURE_TYPES.NETWORK_SEGMENT,
        constants.FEATURE_TYPES.POI
      ],
      LIGHT: [],
      HIDDEN: [
        constants.FEATURE_TYPES.NODE,
        constants.FEATURE_TYPES.REFERENCE_NODE,
        constants.FEATURE_TYPES.ZONE
      ]
    },
    [constants.MAIN_MODES.FLOORPLAN]: {
      DRAW: [
        constants.FEATURE_TYPES.ANNOTATION,
        constants.FEATURE_TYPES.EDGE,
        constants.FEATURE_TYPES.FACILITY,
        constants.FEATURE_TYPES.NODE,
        constants.FEATURE_TYPES.POI,
        constants.FEATURE_TYPES.REFERENCE_NODE
      ],
      BRIGHT: [],
      LIGHT: [],
      HIDDEN: [
        constants.FEATURE_TYPES.JUNCTION,
        constants.FEATURE_TYPES.NETWORK_SEGMENT,
        constants.FEATURE_TYPES.ZONE
      ]
    },
    [constants.MAIN_MODES.NETWORK]: {
      DRAW: [constants.FEATURE_TYPES.JUNCTION, constants.FEATURE_TYPES.NETWORK_SEGMENT],
      BRIGHT: [],
      LIGHT: [
        constants.FEATURE_TYPES.ANNOTATION,
        constants.FEATURE_TYPES.EDGE,
        constants.FEATURE_TYPES.FACILITY,
        constants.FEATURE_TYPES.POI
      ],
      HIDDEN: [
        constants.FEATURE_TYPES.NODE,
        constants.FEATURE_TYPES.REFERENCE_NODE,
        constants.FEATURE_TYPES.ZONE
      ]
    },
    [constants.MAIN_MODES.ZONE]: {
      DRAW: [constants.FEATURE_TYPES.NODE, constants.FEATURE_TYPES.ZONE],
      BRIGHT: [],
      LIGHT: [
        constants.FEATURE_TYPES.ANNOTATION,
        constants.FEATURE_TYPES.EDGE,
        constants.FEATURE_TYPES.JUNCTION,
        constants.FEATURE_TYPES.NETWORK_SEGMENT,
        constants.FEATURE_TYPES.POI
      ],
      HIDDEN: [constants.FEATURE_TYPES.FACILITY, constants.FEATURE_TYPES.REFERENCE_NODE]
    }
  }
};
