import ApiService from '../services/ApiService';
import { getField, updateField } from 'vuex-map-fields';
import confirm from '../helpers/confirm';
import i18n from '../i18n';
import { ExchangePoint, Site, Venue } from '@/types/types';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import constants from '~/shared/constants';

// these fields need to exist to become reactive
const emptyVenue: Venue = {
  name: undefined,
  description: undefined,
  organizationId: undefined,
  sites: [],
  styles: { standard: undefined, light: undefined, dark: undefined },
  webhookUrl: undefined,
  webhookApiKey: undefined,
  lastWebhookResponse: undefined,
  data: undefined
};

type VenueState = {
  venues: Venue[];
  isLoading: boolean;
  isNew: boolean;
  publishing: boolean;
  editedVenue?: Venue;
  editingVenueConnections: boolean;
  onLevel: number;
  siteToHide?: string;
  exchangePointStartId?: string;
  forceUpdateDraw: boolean;
};

const state: VenueState = {
  venues: [],
  isLoading: false,
  isNew: false,
  publishing: false,
  editedVenue: undefined,
  editingVenueConnections: false,
  onLevel: 0,
  siteToHide: undefined,
  exchangePointStartId: undefined,
  forceUpdateDraw: false
};

function getSiteIdByFeatureId(sites: Site[], id: string): string | void {
  for (const site of sites) {
    if (site.features) {
      for (const feature of site.features) {
        if (feature.id === id) {
          return site.id;
        }
      }
    }
  }
}

function getFeatureById(sites: Site[], id: string): any | void {
  for (const site of sites) {
    if (site.features) {
      for (const feature of site.features) {
        if (feature.id === id) {
          return { ...feature.feature, id: feature.id };
        }
      }
    }
  }
}

function getLevelOrderById(sites: Site[], id: string): number | void {
  for (const site of sites) {
    if (site.levels) {
      for (const level of site.levels) {
        if (level.id === id) {
          return level.order;
        }
      }
    }
  }
}

function getExchangePointByJunctions(
  exchangePoints: ExchangePoint[],
  fromJunction: string,
  toJunction: string
): ExchangePoint | void {
  for (const exchangePoint of exchangePoints) {
    if (exchangePoint.fromJunction === fromJunction && exchangePoint.toJunction === toJunction) {
      return exchangePoint;
    }
  }
}

const actions: ActionTree<VenueState, any> = {
  async getAll({ commit }) {
    commit('isLoading');
    const { data } = await ApiService.getVenues();
    commit('getAll', data);
  },

  addNew({ commit }) {
    commit('addNew');
  },

  async edit({ commit }, venueId) {
    const { data } = await ApiService.getVenue(venueId);
    commit('edit', data);
  },

  async editVenueConnections({ commit }) {
    if (!state.editedVenue?.data) {
      const { data } = await ApiService.getVenueData(state.editedVenue!.id!);
      commit('setConnectionsData', data);
    }
    commit('editConnections');
    if (state.editedVenue?.data?.exchangePoints && state.editedVenue.data.sites) {
      for (const ep of state.editedVenue.data.exchangePoints) {
        getFeatureById(state.editedVenue.data.sites, ep.fromJunction).properties.used = true;
        getFeatureById(state.editedVenue.data.sites, ep.toJunction!).properties.used = true;
      }
    }
  },

  saveVenueConnections({ commit }) {
    commit('saveConnections');
  },

  setConnectionEndpoint({ commit }, id) {
    if (state.exchangePointStartId && id) {
      const exchangePoint: ExchangePoint = {
        type: constants.EXCHANGE_POINT_TYPES.SITE_TRANSITION,
        method: constants.EXCHANGE_POINT_METHODS.VIRTUAL_PATH,
        fromJunction: state.exchangePointStartId,
        toJunction: id,
        venueId: state.editedVenue!.id
      };
      commit('addExchangePoint', exchangePoint);
    } else if (id) {
      const siteId = getSiteIdByFeatureId(state.editedVenue!.data!.sites!, id);
      commit('setExchangePointStartId', { id, siteId });
    } else {
      commit('cancelExchangePoint');
    }
  },

  cancelExchangePoint({ commit }) {
    commit('cancelExchangePoint');
  },

  updateExchangePoint({ commit }, updatedExchangePoint) {
    commit('updateExchangePoint', updatedExchangePoint);
  },

  async removeExchangePoint({ commit }, id) {
    const answer = await confirm(i18n.t('Are you sure you want to delete this transition?'), {
      title: i18n.t('Delete transition'),
      buttonTrueText: i18n.t('Delete'),
      buttonFalseText: i18n.t('Cancel')
    });

    if (answer) {
      commit('removeExchangePoint', id);
    }
  },

  async publish({ commit, dispatch }, venueId) {
    const answer = await confirm(i18n.t('Are you sure you want to publish this venue?'), {
      title: i18n.t('Publish venue'),
      buttonTrueText: i18n.t('Publish'),
      buttonFalseText: i18n.t('Cancel')
    });

    if (answer) {
      try {
        commit('setPublishing', true);
        const { data } = await ApiService.publish(venueId);
        const status = await ApiService.status(venueId, data);
        if (status === constants.PUBLISH_STATUS.FINISHED) {
          dispatch('alert/success', null, { root: true });
          dispatch('getAll');
        } else {
          dispatch('alert/error', null, { root: true });
        }
      } finally {
        commit('setPublishing', false);
      }
    }
  },

  async unpublish({ dispatch }, venueId) {
    const answer = await confirm(i18n.t('Are you sure you want to unpublish this venue?'), {
      title: i18n.t('Unpublish venue'),
      buttonTrueText: i18n.t('Unpublish'),
      buttonFalseText: i18n.t('Cancel')
    });

    if (answer) {
      await ApiService.unpublish(venueId);
      dispatch('alert/success', null, { root: true });
      dispatch('getAll');
    }
  },

  async removeSite({ commit }, siteId) {
    const answer = await confirm(i18n.t('Are you sure you want to remove this site?'), {
      title: i18n.t('Remove site'),
      buttonTrueText: i18n.t('Remove'),
      buttonFalseText: i18n.t('Cancel')
    });

    if (answer) {
      commit('removeSite', siteId);
    }
  },

  async save({ commit, state, dispatch }) {
    const venue: Venue = {
      ...state.editedVenue,
      siteIds: state.editedVenue!.sites!.map((site) => site.id as string)
    };

    delete venue.sites;
    delete venue.data?.sites;

    await ApiService.saveVenue(venue);
    commit('resetStore');
    dispatch('alert/success', null, { root: true });
    dispatch('getAll');
  },

  async delete({ commit, dispatch }, venueId) {
    const answer = await confirm(i18n.t('Are you sure you want to delete this venue?'), {
      title: i18n.t('Delete venue'),
      buttonTrueText: i18n.t('Delete'),
      buttonFalseText: i18n.t('Cancel')
    });

    if (answer) {
      await ApiService.deleteVenue(venueId);
      commit('resetStore');
      dispatch('alert/success', null, { root: true });
      dispatch('getAll');
    }
  },

  cancel({ commit }) {
    commit('resetStore');
  },

  changeLevel({ commit }, newLevel) {
    commit('changeLevel', newLevel);
  },

  updateDrawForced({ commit }) {
    commit('setForceUpdateDraw', false);
  }
};

const mutations: MutationTree<VenueState> = {
  getAll(state, venues) {
    state.venues = venues;
    state.isLoading = false;
  },

  isLoading(state) {
    state.isLoading = true;
  },

  addNew(state) {
    state.editedVenue = { ...emptyVenue, sites: [] };
    state.isNew = true;
  },

  setOrganization(state, organizationId) {
    state.editedVenue!.organizationId = organizationId;
    if (organizationId) {
      state.editedVenue!.sites = state.editedVenue!.sites!.filter(
        (site) => site.organizationId === organizationId
      );
    }
  },

  addSite(state, payload) {
    if (state.editedVenue!.sites!.every((site) => site.id !== payload.id)) {
      state.editedVenue!.sites!.push(payload);
    }
  },

  removeSite(state, siteId) {
    state.editedVenue!.sites = state.editedVenue!.sites!.filter((site) => site.id !== siteId);
    if (
      state.editedVenue?.data?.exchangePoints &&
      state.editedVenue.data.exchangePoints.length > 0
    ) {
      state.editedVenue.data.exchangePoints = state.editedVenue.data.exchangePoints.filter(
        (exchangePoint) => {
          const fromSiteId = getSiteIdByFeatureId(
            state.editedVenue!.data!.sites!,
            exchangePoint.fromJunction
          );
          const toSiteId = getSiteIdByFeatureId(
            state.editedVenue!.data!.sites!,
            exchangePoint.toJunction!
          );
          return fromSiteId !== siteId && toSiteId !== siteId;
        }
      );
    }
  },

  edit(state, payload) {
    state.editedVenue = { ...emptyVenue, ...payload };
    state.isNew = false;
  },

  resetStore(state) {
    state.editedVenue = undefined;
    state.isNew = false;
    state.onLevel = 0;
    state.siteToHide = undefined;
    state.exchangePointStartId = undefined;
  },

  setPublishing(state, value) {
    state.publishing = value;
  },

  setConnectionsData(state, data) {
    state.editedVenue!.data = { ...data };
  },

  editConnections(state) {
    state.editingVenueConnections = true;
  },

  saveConnections(state) {
    state.editedVenue!.data!.modified = true;
    state.editingVenueConnections = false;
  },

  setExchangePointStartId(state, { id, siteId }) {
    state.exchangePointStartId = id;
    const feature = getFeatureById(state.editedVenue!.data!.sites!, id);
    feature.properties.used = true;
    feature.properties.highlight = true;
    state.siteToHide = siteId;
  },

  addExchangePoint(state, exchangePoint: ExchangePoint) {
    if (!state.editedVenue?.data?.exchangePoints) {
      state.editedVenue!.data!.exchangePoints = [];
    }
    getFeatureById(
      state.editedVenue!.data!.sites!,
      exchangePoint.toJunction!
    ).properties.used = true;
    getFeatureById(
      state.editedVenue!.data!.sites!,
      exchangePoint.fromJunction!
    ).properties.highlight = false;
    state.editedVenue!.data!.exchangePoints!.push(exchangePoint);
    state.exchangePointStartId = undefined;
    state.siteToHide = undefined;
  },

  cancelExchangePoint(state) {
    if (state.exchangePointStartId) {
      const feature = getFeatureById(state.editedVenue!.data!.sites!, state.exchangePointStartId);
      feature.properties.used = false;
      feature.properties.highlight = false;
    }
    state.exchangePointStartId = undefined;
    state.siteToHide = undefined;
  },

  updateExchangePoint(state, exchangePoint: ExchangePoint) {
    if (state.editedVenue?.data?.exchangePoints) {
      const oldExchangePoint = getExchangePointByJunctions(
        state.editedVenue.data.exchangePoints,
        exchangePoint.fromJunction,
        exchangePoint.toJunction!
      );
      if (oldExchangePoint) {
        oldExchangePoint.id = exchangePoint.id;
        oldExchangePoint.type = exchangePoint.type;
        oldExchangePoint.method = exchangePoint.method;
      }
    }
  },

  removeExchangePoint(state, id) {
    const exchangePoint = state.editedVenue?.data?.exchangePoints?.find((ep) => ep.id === id);
    if (exchangePoint) {
      getFeatureById(
        state.editedVenue!.data!.sites!,
        exchangePoint.toJunction!
      ).properties.used = false;
      getFeatureById(
        state.editedVenue!.data!.sites!,
        exchangePoint.fromJunction!
      ).properties.used = false;
      state.editedVenue!.data!.exchangePoints = state.editedVenue!.data!.exchangePoints!.filter(
        (ep) => ep.id !== id
      );
    }
    state.forceUpdateDraw = true;
  },

  changeLevel(state, newLevel) {
    state.onLevel = newLevel;
  },

  setForceUpdateDraw(state, value) {
    state.forceUpdateDraw = value;
  },

  updateField
};

const getters: GetterTree<VenueState, any> = {
  venues: (state) => state.venues,
  editedVenue: (state) => state.editedVenue,
  editedVenueData: (state) => state.editedVenue?.data,
  isNew: (state) => state.isNew,
  isLoading: (state) => state.isLoading,
  publishing: (state) => state.publishing,
  editingVenueConnections: (state) => state.editingVenueConnections,
  forceUpdateDraw: (state) => state.forceUpdateDraw,
  onLevel: (state) => state.onLevel,
  onLevelIds: (state) => {
    const levelIds = [];
    if (state.editedVenue?.data?.sites) {
      for (const site of state.editedVenue?.data?.sites) {
        levelIds.push(site.levels?.find((level) => level.order === state.onLevel)?.id);
      }
    }
    return levelIds.filter((levelId) => levelId);
  },
  levelEnds: (state) => {
    if (state.editedVenue?.data?.sites) {
      let minLevel = 1000;
      let maxLevel = -1000;
      for (const site of state.editedVenue.data.sites) {
        for (const level of site.levels!) {
          if (level.order < minLevel) {
            minLevel = level.order;
          }
          if (level.order > maxLevel) {
            maxLevel = level.order;
          }
        }
      }
      return { minLevel, maxLevel };
    }
  },
  siteToHide: (state) => state.siteToHide,
  exchangePointToFeature: (state) => (exchangePoint: ExchangePoint) => {
    const fromJunction = getFeatureById(
      state.editedVenue!.data!.sites!,
      exchangePoint.fromJunction
    );
    const toJunction = getFeatureById(state.editedVenue!.data!.sites!, exchangePoint.toJunction!);
    const feature = {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [[...fromJunction.geometry.coordinates], [...toJunction.geometry.coordinates]]
      },
      id: exchangePoint.id,
      properties: {
        id: exchangePoint.id,
        type: exchangePoint.type,
        method: exchangePoint.method,
        venueId: exchangePoint.venueId,
        _type: constants.FEATURE_TYPES.VENUE_CONNECTION
      }
    };
    return feature;
  },
  getSiteNameOfFeature: (state) => (feature: any) => {
    const siteId = getSiteIdByFeatureId(state.editedVenue!.data!.sites!, feature.id);
    return state.editedVenue!.data!.sites!.find((site) => site.id === siteId)?.name;
  },
  isOnLevel: (state) => (exchangePoint: ExchangePoint) => {
    if (state.editedVenue?.data?.sites) {
      const fromJunction = getFeatureById(
        state.editedVenue!.data!.sites!,
        exchangePoint.fromJunction
      );
      const toJunction = getFeatureById(state.editedVenue!.data!.sites!, exchangePoint.toJunction!);
      const fromLevel = getLevelOrderById(
        state.editedVenue.data.sites,
        fromJunction.properties.levelId
      );
      const toLevel = getLevelOrderById(
        state.editedVenue.data.sites,
        toJunction.properties.levelId
      );
      if (fromLevel === toLevel) {
        return fromLevel === state.onLevel;
      }
    }
  },

  getField
};

export const venue = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
