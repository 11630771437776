import Vue from 'vue';
import moment from 'moment-mini';
import * as dateFormats from '../helpers/dateFormats';

Vue.filter('dateTime', function(value: Date) {
  return moment(value).format(dateFormats.getDisplayFormatDateTime());
});

Vue.filter('date', function(value: Date) {
  return moment(value).format(dateFormats.getDisplayFormatDate());
});

Vue.filter('time', function(value: Date) {
  return moment(value).format(dateFormats.getDisplayFormatTime());
});
