import ApiService from '../services/ApiService';

const state = {
  config: null,
  lastBaseMapLayerId: null,
  version: null
};

const actions = {
  async fetchVersion({ commit }) {
    const { data } = await ApiService.getVersion();
    commit('setVersion', data.version);
  },

  async fetchConfig({ commit }) {
    const { data: config } = await ApiService.getConfig();
    commit('setConfig', config);
    if (config.disableAuth) {
      commit('account/disableAuth', null, { root: true });
    }
  }
};

const mutations = {
  setVersion(state, payload) {
    state.version = payload;
  },

  setLastBaseMapLayerId(state, payload) {
    state.lastBaseMapLayerId = payload;
  },

  setConfig(state, payload) {
    state.config = payload;
  }
};

const getters = {
  lastBaseMapLayerId: (state) => state.lastBaseMapLayerId,
  version: (state) => state.version,
  additionalLayers: (state) => state.config.additionalLayers
};

export const app = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
