import { router } from '../router';
import ApiService from '../services/ApiService';
import jwt_decode from 'jwt-decode';
import { localStorageKey } from '../services/AxiosInstance';
import constants from '~/shared/constants';

const state = { authDisabled: false, status: { loggedIn: false }, user: null };

const actions = {
  async refreshToken({ state, dispatch, commit }) {
    if (state.authDisabled) {
      return;
    }

    const { data } = await ApiService.refreshToken();
    if (data.token) {
      localStorage.setItem(localStorageKey, data.token);
      let decoded = jwt_decode(data.token);
      // token exp - 1 min
      const timeout = new Date(decoded.exp * 1000) - new Date() - 60000;

      dispatch('queueTokenRefresh', timeout);
      if (!state.status.isLoggedIn) {
        commit('loginSuccess', {
          token: data.token,
          user: {
            username: decoded.username,
            role: decoded.role,
            organizationId: decoded.organizationId
          }
        });
      }
    } else {
      dispatch('logout');
    }
  },

  queueTokenRefresh({ dispatch, commit }, timeout) {
    const refreshHandler = setTimeout(async () => {
      dispatch('refreshToken');
    }, timeout);

    commit('queueTokenRefresh', { refreshHandler });
  },

  async login({ dispatch, commit }, { username, password }) {
    try {
      const { data } = await ApiService.login(username, password);

      localStorage.setItem(localStorageKey, data.token);

      let decoded = jwt_decode(data.token);
      // token exp - 1 min
      const timeout = new Date(decoded.exp * 1000) - new Date() - 60000;

      dispatch('queueTokenRefresh', { timeout });
      commit('loginSuccess', { ...data });
      dispatch('status/setCurrentMainMode', constants.MAIN_MODES.STATIC, { root: true });
      router.push('/');
    } catch (error) {
      commit('loginFailure');
      dispatch('alert/error', error.response?.data?.message || error, { root: true });
    }
  },

  async logout({ commit }) {
    clearInterval(state.refreshHandler);

    await ApiService.logout();
    localStorage.removeItem(localStorageKey);

    commit('logout');
    router.push('/login');
  }
};

const getters = {
  isUserLoggedIn: (state) => {
    return state.status && state.status.loggedIn;
  },

  isSuperAdmin: (state) => {
    return (
      state.status &&
      state.status.loggedIn &&
      state.user &&
      state.user.role === constants.ROLES.SUPER_ADMIN
    );
  },

  isAdmin: (state) => {
    return (
      state.status &&
      state.status.loggedIn &&
      state.user &&
      (state.user.role === constants.ROLES.SUPER_ADMIN ||
        state.user.role === constants.ROLES.ORG_ADMIN)
    );
  },
  isEditor: (state) => {
    return (
      state.status &&
      state.status.loggedIn &&
      state.user &&
      state.user.role === constants.ROLES.MAPPER
    );
  },
  isOperator: (state) => {
    return (
      state.status &&
      state.status.loggedIn &&
      state.user &&
      state.user.role === constants.ROLES.OPERATOR
    );
  },
  canEdit: (state, getters) => getters.isAdmin || getters.isEditor || state.authDisabled,
  displayName: (state) => {
    if (!state.status.loggedIn) {
      return undefined;
    }

    return state.user.username + ' (' + state.user.role + ')';
  },
  organizationId: (state) => {
    return state.user?.organizationId;
  }
};

const mutations = {
  disableAuth(state) {
    state.authDisabled = true;
  },

  loginSuccess(state, payload) {
    state.status = { loggedIn: true };
    state.user = {
      username: payload.user.username,
      role: payload.user.role,
      organizationId: payload.user.organizationId
    };
    state.token = payload.token;
  },
  loginFailure(state) {
    state.status = { isLoggedIn: false };
    state.user = null;
  },
  queueTokenRefresh(state, payload) {
    state.refreshHandler = payload.refreshHandler;
  },

  logout(state) {
    state.status = { isLoggedIn: false };
    state.user = null;
    state.token = null;
    state.refreshHandler = null;
  }
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
