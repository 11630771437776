import constants from '~/shared/constants';
import { wait } from '~/shared/utils';
import confirm from '../helpers/confirm';
import i18n from '../i18n';

// these fields need to exist to become reactive
const emptyStairs = {
  id: null,
  levelId: null,
  feature: {
    type: 'Feature',
    geometry: {
      coordinates: null,
      type: 'Point'
    },
    properties: {
      _type: constants.FEATURE_TYPES.JUNCTION,
      type: constants.JUNCTION_TYPES.STAIRS,
      levelId: null,
      direction: null
    }
  }
};

const emptyState = {
  editedStairs: null,
  editedConnectedStairs: null,
  isNew: false,

  addStartedOnLevelId: undefined,
  stairsFeature: undefined
};
const state = {
  ...emptyState
};

const actions = {
  async add({ dispatch, commit, state, rootGetters }, payload) {
    if (state.addStartedOnLevelId) {
      const newExchangePoint = {
        type: constants.EXCHANGE_POINT_TYPES.LEVEL_TRANSITION,
        method: constants.EXCHANGE_POINT_METHODS.VIRTUAL_PATH,
        _siteId: rootGetters['site/editedSite'].id,
        fromJunction: state.stairsFeature.id,
        toJunction: payload.feature.id
      };

      dispatch('feature/addExchangePoint', newExchangePoint, { root: true });
      dispatch('updateStairsDirections', rootGetters['level/editedLevelId']);

      dispatch('history/add', constants.OPERATIONS.NETWORK.ADD_STAIRS, { root: true });
      dispatch('alert/success', i18n.t('The stairs were created successfully.'), { root: true });
      await wait(500);

      dispatch('level/change', state.addStartedOnLevelId, { root: true });
    }

    commit('add', { feature: payload.feature, levelId: rootGetters['level/editedLevelId'] });
  },

  async delete(
    { commit, rootGetters, dispatch, getters },
    { stairsId, openConfirmPopup = true, deleteOriginalFeature = false }
  ) {
    let answer = true;
    if (openConfirmPopup) {
      answer = await confirm(i18n.t('Are you sure you want to delete these stairs?'), {
        title: i18n.t('Delete stairs'),
        buttonTrueText: i18n.t('Delete'),
        buttonFalseText: i18n.t('Cancel')
      });
    }

    if (answer) {
      let { stairs, connectedStairs, exchangePoint } = getters.getStairsData({ stairsId });

      const netSegConnectedToConnectedStairs = await dispatch(
        'feature/networkSegmentIsConnected',
        {
          junctionId: connectedStairs.id,
          levelId: connectedStairs.feature.properties.levelId
        },
        { root: true }
      );
      if (netSegConnectedToConnectedStairs) {
        connectedStairs.feature.properties.direction = undefined;
        connectedStairs.feature.properties.type = constants.JUNCTION_TYPES.NORMAL;
        commit('feature/editFeature', connectedStairs, { root: true });
      } else {
        commit('feature/deleteFeature', connectedStairs.id, { root: true });
      }

      commit('feature/deleteExchangePoint', exchangePoint, { root: true });

      commit('reset');

      const netSegConnectedToEditedStairs = await dispatch(
        'feature/networkSegmentIsConnected',
        {
          junctionId: stairs.id,
          levelId: stairs.feature.properties.levelId
        },
        { root: true }
      );

      stairs.feature.properties.direction = undefined;
      stairs.feature.properties.type = constants.JUNCTION_TYPES.NORMAL;
      if (netSegConnectedToEditedStairs && !deleteOriginalFeature) {
        commit('feature/editFeature', stairs, { root: true });
      } else {
        commit('feature/deleteFeature', stairsId, { root: true });
      }

      if (openConfirmPopup) {
        const editedLevelId = rootGetters['level/editedLevelId'];
        commit('feature/updateCurrentFeatures', editedLevelId, { root: true });
        dispatch('history/add', constants.OPERATIONS.FLOORPLAN.DELETE, { root: true });
      }
      return netSegConnectedToEditedStairs ? stairs : null;
    }
  },

  async updateStairsDirections({ getters, rootGetters, commit }, movedLevelId) {
    const features = rootGetters['feature/featuresByLevel'];
    const stairsOfLevel = features[movedLevelId].filter(
      (feature) => feature.feature.properties.type === constants.JUNCTION_TYPES.STAIRS
    );
    const levels = rootGetters['level/levels'];

    // go through all stairs on this floor
    for (let stairs of stairsOfLevel) {
      let { connectedStairs } = getters.getStairsData({ stairs });
      let editedStairs = JSON.parse(JSON.stringify(stairs));

      // levels is an ordered array, get stairs' indices to set their directions
      const stairsLevelIdx = levels.findIndex(
        (level) => level.id === stairs.feature.properties.levelId
      );
      const connectedStairsLevelIdx = levels.findIndex(
        (level) => level.id === connectedStairs.feature.properties.levelId
      );

      editedStairs.feature.properties.direction =
        stairsLevelIdx < connectedStairsLevelIdx
          ? constants.STAIRS_TYPES.DOWNWARDS
          : constants.STAIRS_TYPES.UPWARDS;
      connectedStairs.feature.properties.direction =
        connectedStairsLevelIdx < stairsLevelIdx
          ? constants.STAIRS_TYPES.DOWNWARDS
          : constants.STAIRS_TYPES.UPWARDS;

      commit('feature/editFeature', editedStairs, { root: true });
      commit('feature/editFeature', connectedStairs, { root: true });
    }

    const editedLevelId = rootGetters['level/editedLevelId'];
    commit('feature/updateCurrentFeatures', editedLevelId, { root: true });
  },

  reset({ commit }) {
    commit('reset');
  }
};

const mutations = {
  reset(state) {
    Object.assign(state, emptyState);
  },

  add(state, payload) {
    if (!state.addStartedOnLevelId) {
      state.addStartedOnLevelId = payload.levelId;
      state.stairsFeature = payload.feature;
    } else {
      state.addStartedOnLevelId = undefined;
      state.stairsFeature = undefined;
    }
  },

  editStairs(state, payload) {
    state.editedStairs = payload.editedStairs;
    state.editedConnectedStairs = payload.connectedStairs;
    state.isNew = false;
  }
};

const getters = {
  isAddInProgress: (state) => !!state.addStartedOnLevelId,
  addStartedOnLevelId: (state) => state.addStartedOnLevelId,
  editedStairs: (state) => state.editedStairs,
  editedConnectedStairs: (state) => state.editedConnectedStairs,
  connectedStairsLevelName: (state, getters, rootState, rootGetters) => (id) => {
    const stairsData = getters.getStairsData({ stairsId: id });
    let levels = rootGetters['level/levels'];
    levels = levels.filter((level) => level.id === stairsData.connectedStairs.levelId);
    if (levels && levels.length === 1) {
      return levels[0].name;
    }
  },
  getStairsData: (state, getters, rootState, rootGetters) => ({ stairsId, stairs }) => {
    // returns the copied objects of:
    // stairs of incoming stairsId (or the incoming stairs withouth modification),
    // the connected stairs on other level, and their exchange point
    const featuresByLevel = rootGetters['feature/featuresByLevel'];

    if (!stairs && !stairsId) {
      return {};
    } else if (!stairs) {
      const editedLevelId = rootGetters['level/editedLevelId'];
      stairs = featuresByLevel[editedLevelId].find((feature) => feature.id === stairsId);

      // if not found on edited level, then find on all levels
      if (!stairs) {
        for (let levelFeatures of Object.values(featuresByLevel)) {
          stairs = levelFeatures.find((feature) => feature.id === stairsId);
          if (stairs) {
            break;
          }
        }
      }

      stairs = JSON.parse(JSON.stringify(stairs));
    } else if (!stairsId) {
      stairsId = stairs.id;
    }

    // find the connected exchange point
    const exchangePoints = rootGetters['feature/exchangePoints'];
    let exchangePoint = exchangePoints.find(
      (exchp) => exchp.fromJunction === stairsId || exchp.toJunction === stairsId
    );
    exchangePoint = JSON.parse(JSON.stringify(exchangePoint));

    const connectedStairsId =
      exchangePoint.fromJunction === stairsId
        ? exchangePoint.toJunction
        : exchangePoint.fromJunction;
    let connectedStairs = JSON.parse(JSON.stringify(emptyStairs));

    // find connected stairs on other levels using id from exchange point
    for (let levelId in featuresByLevel) {
      let featuresOfLevel = featuresByLevel[levelId];
      let foundConnectedStairs = featuresOfLevel.find(
        (feature) =>
          feature.feature.properties.type === constants.JUNCTION_TYPES.STAIRS &&
          feature.id === connectedStairsId
      );

      if (foundConnectedStairs) {
        connectedStairs.levelId = levelId;
        connectedStairs = Object.assign(
          connectedStairs,
          JSON.parse(JSON.stringify(foundConnectedStairs))
        );
        break;
      }
    }

    return { stairs, connectedStairs, exchangePoint };
  },
  isNew: (state) => state.isNew
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
