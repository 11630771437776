import Vuetify, { colors } from 'vuetify/lib';

import Vue from 'vue';
import VuetifyConfirm from 'vuetify-confirm';
import '@mdi/font/css/materialdesignicons.css';
import hu from 'vuetify/es5/locale/hu';
import i18n from '../i18n';
import { Ripple } from 'vuetify/lib/directives';

Vue.use(Vuetify, {
  directives: {
    Ripple
  }
});

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0081a7',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        active: colors.blue
      },
      dark: {}
    },
    options: {
      customProperties: true
    }
  },
  lang: {
    locales: {
      hu
    },
    current: i18n.locale
  },
  icons: {
    iconfont: 'mdi'
  }
});

// https://www.npmjs.com/package/vuetify-confirm
Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: 'Yes',
  buttonFalseText: 'No',
  color: 'error',
  icon: 'warning',
  title: 'Warning',
  width: 450,
  property: '$confirm'
});

export default vuetify;
