import { getField, updateField } from 'vuex-map-fields';
import constants from '~/shared/constants';
import { getNewUniqueId } from '~/shared/utils';

const emptyReferenceNode = {
  id: null,
  _type: constants.FEATURE_TYPES.REFERENCE_NODE,
  _siteId: null,
  levelId: null,
  feature: {
    type: 'Feature',
    geometry: {
      coordinates: null,
      type: 'Point'
    },
    properties: {
      id: null,
      _type: constants.FEATURE_TYPES.REFERENCE_NODE,
      levelId: null
    }
  }
};

const state = {
  transparency: 30,
  visible: true
};

const actions = {
  addNewReferenceNode({ commit, dispatch, rootGetters }, payload) {
    const allLevels = rootGetters['level/levels'];
    for (const level of allLevels) {
      let newReferenceNode = JSON.parse(JSON.stringify(emptyReferenceNode));

      const newFeatureId = getNewUniqueId();
      newReferenceNode.id = newFeatureId;
      newReferenceNode.feature.properties.id = newFeatureId;

      newReferenceNode._siteId = rootGetters['site/editedSite'].id;

      const levelId = level.id;
      newReferenceNode.levelId = JSON.parse(JSON.stringify(levelId));
      newReferenceNode.feature.properties.levelId = JSON.parse(JSON.stringify(levelId));

      newReferenceNode.feature.geometry = payload.feature.geometry;

      commit('feature/addFeature', newReferenceNode, { root: true });
    }

    dispatch('history/add', constants.OPERATIONS.FLOORPLAN.ADD_REFERENCE_NODE, { root: true });
  },

  delete({ commit, rootGetters }, payload) {
    let coordinates = payload.coords;
    const featuresByLevel = rootGetters['feature/featuresByLevel'];
    if (!coordinates) {
      const feature = featuresByLevel[payload.levelId].find((feature) => feature.id === payload.id);
      coordinates = feature.feature.geometry.coordinates;
    }

    for (const levelId in featuresByLevel) {
      const featuresByOneLevel = featuresByLevel[levelId];

      const connectedRefNode = featuresByOneLevel.find(
        (feature) =>
          feature.feature.properties._type === constants.FEATURE_TYPES.REFERENCE_NODE &&
          feature.feature.geometry.coordinates[0] === coordinates[0] &&
          feature.feature.geometry.coordinates[1] === coordinates[1]
      );

      commit('feature/deleteFeature', connectedRefNode.id, { root: true });
    }
  },

  move({ commit, rootGetters }, payload) {
    const referenceNodesOnCurrentLevel = payload;
    const featuresByLevel = rootGetters['feature/featuresByLevel'];

    const editedLevelId = rootGetters['level/editedLevelId'];

    for (let movedRefNode of referenceNodesOnCurrentLevel) {
      const feature = featuresByLevel[movedRefNode.properties.levelId].find(
        (feature) => feature.id === movedRefNode.id
      );
      if (!feature) {
        continue;
      }

      const prevCoords = feature.feature.geometry.coordinates;
      for (const levelId in featuresByLevel) {
        if (levelId === editedLevelId) {
          continue;
        }

        const featuresByOneLevel = featuresByLevel[levelId];

        let connectedRefNode = featuresByOneLevel.find(
          (feature) =>
            feature.feature.properties._type === constants.FEATURE_TYPES.REFERENCE_NODE &&
            feature.feature.geometry.coordinates[0] === prevCoords[0] &&
            feature.feature.geometry.coordinates[1] === prevCoords[1]
        );
        connectedRefNode = JSON.parse(JSON.stringify(connectedRefNode));
        connectedRefNode.feature.geometry.coordinates = movedRefNode.coordinates;

        commit('feature/editFeature', connectedRefNode, { root: true });
      }
    }
  }
};

const mutations = {
  changeTransparency(state, payload) {
    state.transparency = payload;
  },

  changeVisibility(state, payload) {
    state.visible = payload;
  },

  updateField
};

const getters = {
  transparency: (state) => state.transparency,
  visible: (state) => state.visible,
  getField
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
