import {
  helpers as turfHelpers,
  booleanPointOnLine as turfIsPointOnLine,
  length as turfLength,
  lineIntersect as turfLineIntersect
} from '@turf/turf';
import constants from './constants';

let uniqueId = 0;
export const getNewUniqueId = () => {
  uniqueId++;
  return uniqueId.toString();
};

export const isNodeOrJunction = (feature) => {
  return (
    feature &&
    (feature.properties._type === constants.FEATURE_TYPES.NODE ||
      feature.properties._type === constants.FEATURE_TYPES.JUNCTION)
  );
};

export const isEdgeOrNetworkSegment = (feature) => {
  return (
    feature &&
    !feature.properties.guideline &&
    !feature.properties.measure_guides &&
    (feature.properties._type === constants.FEATURE_TYPES.EDGE ||
      feature.properties._type === constants.FEATURE_TYPES.NETWORK_SEGMENT)
  );
};

export const getFromPropertyName = (type) => {
  switch (type) {
    case constants.FEATURE_TYPES.EDGE: {
      return 'fromNode';
    }
    case constants.FEATURE_TYPES.NETWORK_SEGMENT:
    case constants.FEATURE_TYPES.EXCHANGE_POINT:
    case constants.FEATURE_TYPES.JUNCTION: {
      return 'fromJunction';
    }
    default:
      throw 'Invalid feature type';
  }
};

export const getToPropertyName = (type) => {
  switch (type) {
    case constants.FEATURE_TYPES.EDGE: {
      return 'toNode';
    }
    case constants.FEATURE_TYPES.NETWORK_SEGMENT:
    case constants.FEATURE_TYPES.EXCHANGE_POINT:
    case constants.FEATURE_TYPES.JUNCTION: {
      return 'toJunction';
    }
    default:
      throw 'Invalid feature type';
  }
};

export const splitLine = (line, point) => {
  let lineA = {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: [line.geometry.coordinates[0], point.geometry.coordinates]
    },
    properties: { ...line.properties }
  };
  lineA.properties[getToPropertyName(line.properties._type)] = point.id;
  if (turfLength(lineA) === 0) {
    lineA = undefined;
  }

  let lineB = {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: [point.geometry.coordinates, line.geometry.coordinates[1]]
    },
    properties: { ...line.properties }
  };
  lineB.properties[getFromPropertyName(line.properties._type)] = point.id;

  if (turfLength(lineB) === 0) {
    lineB = undefined;
  }

  if (line.properties.alreadyCreated) {
    if (lineA) {
      delete lineA.properties.alreadyCreated;
    }
    if (lineB) {
      delete lineB.properties.alreadyCreated;
    }
  }

  return [lineA, lineB];
};

export const haveCommonEndpoint = (lineOne, lineTwo) => {
  return (
    lineOne.properties[getFromPropertyName(lineOne.properties._type)] ===
      lineTwo.properties[getFromPropertyName(lineTwo.properties._type)] ||
    lineOne.properties[getFromPropertyName(lineOne.properties._type)] ===
      lineTwo.properties[getToPropertyName(lineTwo.properties._type)] ||
    lineOne.properties[getToPropertyName(lineOne.properties._type)] ===
      lineTwo.properties[getFromPropertyName(lineTwo.properties._type)] ||
    lineOne.properties[getToPropertyName(lineOne.properties._type)] ===
      lineTwo.properties[getToPropertyName(lineTwo.properties._type)]
  );
};

export const tIntersection = (lineOne, lineTwo) => {
  const endpointOneFrom = turfHelpers.point(lineOne.geometry.coordinates[0]);
  const endpointOneTo = turfHelpers.point(lineOne.geometry.coordinates[1]);
  const endpointTwoFrom = turfHelpers.point(lineTwo.geometry.coordinates[0]);
  const endpointTwoTo = turfHelpers.point(lineTwo.geometry.coordinates[1]);
  return (
    turfIsPointOnLine(endpointOneFrom, lineTwo, { ignoreEndVertices: true }) ||
    turfIsPointOnLine(endpointOneTo, lineTwo, { ignoreEndVertices: true }) ||
    turfIsPointOnLine(endpointTwoFrom, lineOne, { ignoreEndVertices: true }) ||
    turfIsPointOnLine(endpointTwoTo, lineOne, { ignoreEndVertices: true })
  );
};

export const wait = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const checkIntersectionsOnLevel = (levelFeatures) => {
  const segments = levelFeatures
    .map((feature) => ({ ...feature.feature, id: feature.id }))
    .filter((feature) => feature.properties._type === constants.FEATURE_TYPES.NETWORK_SEGMENT);

  let i = 0;
  let j = 0;
  let result = {};

  let found = false;
  while (i < segments.length - 1 && !found) {
    j = i + 1;
    while (j < segments.length && !found) {
      const intersection = turfLineIntersect(segments[i], segments[j]);
      if (intersection.features && intersection.features.length === 1) {
        let lineOne = segments[i];
        let lineTwo = segments[j];

        if (haveCommonEndpoint(lineOne, lineTwo) || tIntersection(lineOne, lineTwo)) {
          // no-op
        } else {
          found = true;
          let newPoint = intersection.features[0];
          newPoint.properties.levelId = lineOne.properties.levelId;
          const lineType = lineOne.properties._type;
          if (lineType === constants.FEATURE_TYPES.EDGE) {
            newPoint.properties._type = constants.FEATURE_TYPES.NODE;
          } else if (lineType === constants.FEATURE_TYPES.NETWORK_SEGMENT) {
            newPoint.properties._type = constants.FEATURE_TYPES.JUNCTION;
            newPoint.properties.type = constants.JUNCTION_TYPES.NORMAL;
          }

          let newPointCopy = { ...newPoint };
          newPointCopy.geometry = { ...newPoint.geometry };
          newPointCopy.geometry.coordinates = [...newPoint.geometry.coordinates];
          newPoint.id = getNewUniqueId();

          //create new edges/networksegments
          let lineOneParts = splitLine(lineOne, newPoint);
          let lineTwoParts = splitLine(lineTwo, newPoint);

          let lineOneA = lineOneParts[0];
          let lineOneB = lineOneParts[1];
          let lineTwoA = lineTwoParts[0];
          let lineTwoB = lineTwoParts[1];

          lineOneA.id = getNewUniqueId();
          lineOneB.id = getNewUniqueId();
          lineTwoA.id = getNewUniqueId();
          lineTwoB.id = getNewUniqueId();

          result.point = newPoint;
          result.lineOne = lineOne;
          result.lineTwo = lineTwo;
          result.lineOneA = lineOneA;
          result.lineOneB = lineOneB;
          result.lineTwoA = lineTwoA;
          result.lineTwoB = lineTwoB;
        }
      }
      j++;
    }
    i++;
  }

  if (found) {
    const levelId = result.point.levelId;
    levelFeatures.push({ id: result.point.id, levelId, feature: result.point });
    levelFeatures.push({ id: result.lineOneA.id, levelId, feature: result.lineOneA });
    levelFeatures.push({ id: result.lineOneB.id, levelId, feature: result.lineOneB });
    levelFeatures.push({ id: result.lineTwoA.id, levelId, feature: result.lineTwoA });
    levelFeatures.push({ id: result.lineTwoB.id, levelId, feature: result.lineTwoB });

    levelFeatures = levelFeatures.filter(
      (feature) => feature.id !== result.lineOne.id && feature.id !== result.lineTwo.id
    );

    levelFeatures = checkIntersectionsOnLevel(levelFeatures);
  }

  return levelFeatures;
};

export const checkIntersections = (featuresByLevel) => {
  for (let [levelId, levelFeatures] of Object.entries(featuresByLevel)) {
    const result = checkIntersectionsOnLevel(levelFeatures);
    featuresByLevel[levelId] = result;
  }
  return featuresByLevel;
};

export const changeSvgColor = (svgString, hexCode) => {
  let svg = svgString;
  if (svg.includes('fill')) {
    const starting = svg.indexOf('fill');
    svg = svg.replace(svg.substr(starting, 16), `fill="%23${hexCode}"`);
  } else {
    const split = svg.indexOf('path') + 4;
    svg = `${svg.slice(0, split)} fill="%23${hexCode}"${svg.slice(split)}`;
  }

  return svg;
};

export const rotateSvg = (svgString, degrees) => {
  let svg = svgString;
  if (svg.includes('transform')) {
    const starting = svg.indexOf('transform');
    svg = svg.replace(svg.substr(starting, 22), `${degrees}`);
  } else {
    const split = svg.indexOf('d="') - 1;
    svg = `${svg.slice(
      0,
      split
    )} transform="rotate(${degrees}") transform-origin="center"${svg.slice(split)}`;
  }

  return svg;
};
