<template>
  <div class="languageSelectorWrapper mt-4 mr-2">
    {{ $t('Language') }}:
    <v-select
      class="languageSelector"
      v-model="$i18n.locale"
      :items="[
        { text: $t('Hungarian'), value: 'hu' },
        { text: $t('English'), value: 'en' }
      ]"
    ></v-select>
  </div>
</template>

<style>
.languageSelectorWrapper {
  width: 250px;
  text-align: right;
}

.languageSelector {
  display: inline-flex;
  width: 125px;
  margin-left: 3px;
}
</style>

<script>
import config from '../helpers/configProvider';

export default {
  name: 'LanguageSelector',

  watch: {
    '$i18n.locale': {
      handler(newLocale) {
        this.$vuetify.lang.current = newLocale;
        this.$cookies.set('language', newLocale, Infinity);
        document.title =
          config.getEnv(config.enum.VUE_APP_BRANDING) === 'SENSODOC'
            ? this.$t('SensoDoc Floorplan Editor')
            : this.$t('GLI Floorplan Editor');
      },
      immediate: true
    }
  }
};
</script>
