import ApiService from '../services/ApiService';
import { getField, updateField } from 'vuex-map-fields';
import confirm from '../helpers/confirm';
import i18n from '../i18n';
import { getNewUniqueId } from '~/shared/utils';

// these fields need to exist to become reactive
const emptyOrganization = {
  name: null,
  settings: {
    rasterSources: [],
    styles: [],
    tags: []
  }
};

const emptyRasterSource = {
  name: undefined,
  url: undefined,
  description: undefined
};

const emptyStyle = { name: undefined };

const emptyTag = {
  name: undefined,
  base: 0,
  top: 1
};

const state = {
  organizations: [],
  isLoading: false,
  editedOrganization: null,
  editedRasterSource: undefined,
  editedStyle: undefined,
  editedLayer: undefined,
  editedTag: undefined,
  isNew: false,
  isNewRasterSource: false,
  isNewStyle: false,
  isNewTag: false,
  pageLoaded: false
};

const actions = {
  async getAll({ commit }) {
    commit('isLoading');
    const { data } = await ApiService.getOrganizations();
    commit('getAll', data);
  },

  addNew({ commit }) {
    commit('addNew');
    commit('generateSampleTags');
  },

  async edit({ commit, rootGetters }, organizationId) {
    if (!organizationId) {
      organizationId = rootGetters['account/organizationId'];
    }

    const { data } = await ApiService.getOrganization(organizationId);

    if (!data.settings.rasterSources) {
      data.settings.rasterSources = [];
    }
    if (!data.settings.styles) {
      data.settings.styles = [];
    }
    if (!data.settings.tags) {
      data.settings.tags = [];
    }

    commit('edit', data);
  },

  async save({ commit, state, dispatch, rootGetters }) {
    for (let rasterSource of state.editedOrganization.settings.rasterSources) {
      if (rasterSource.isNew) {
        delete rasterSource.isNew;
        delete rasterSource._id;
      }
    }

    for (let style of state.editedOrganization.settings.styles) {
      if (style.isNew) {
        delete style.isNew;
        delete style._id;
      }
    }

    for (let tag of state.editedOrganization.settings.tags) {
      if (tag.isNew) {
        delete tag.isNew;
        delete tag.id;
      }
    }
    const editedSite = rootGetters['site/editedSite'];
    if (editedSite?.organizationId === state.editedOrganization.id) {
      dispatch('site/refreshEditedSiteStyles', state.editedOrganization.settings.styles, {
        root: true
      });
    }
    await ApiService.saveOrganization(state.editedOrganization);
    commit('save');
    dispatch('alert/success', null, { root: true });
    dispatch('getAll');
  },

  async delete({ commit, dispatch }, organizationId) {
    const answer = await confirm(i18n.t('Are you sure you want to delete this organization?'), {
      title: i18n.t('Delete organization'),
      buttonTrueText: i18n.t('Delete'),
      buttonFalseText: i18n.t('Cancel')
    });

    if (answer) {
      await ApiService.deleteOrganization(organizationId);
      commit('delete');
      dispatch('alert/success', null, { root: true });
      dispatch('getAll');
    }
  },

  cancel({ commit }) {
    commit('cancel');
  },

  addNewRasterSource({ commit }) {
    commit('addNewRasterSource');
  },

  editRasterSource({ commit }, rasterSourceId) {
    commit('editRasterSource', rasterSourceId);
  },

  saveRasterSource({ commit }) {
    commit('saveRasterSource');
  },

  async deleteRasterSource({ commit }, id) {
    const answer = await confirm(i18n.t('Are you sure you want to delete this raster source?'), {
      title: i18n.t('Delete raster source'),
      buttonTrueText: i18n.t('Delete'),
      buttonFalseText: i18n.t('Cancel')
    });

    if (answer) {
      commit('deleteRasterSource', id);
    }
  },

  cancelEditingRasterSource({ commit }) {
    commit('cancelEditingRasterSource');
  },

  addNewStyle({ commit, rootGetters }) {
    const layers = rootGetters['app/additionalLayers'];
    commit('addNewStyle', layers);
  },

  editStyle({ commit, rootGetters }, style) {
    const layers = rootGetters['app/additionalLayers'];

    const layersNotFoundInStyle = layers.filter(
      (layer) => !style.layers.map(({ id }) => id).includes(layer.id)
    );

    style.layers.push(...layersNotFoundInStyle);
    commit('editStyle', { style });
  },

  saveStyle({ commit }) {
    commit('saveStyle');
  },

  async deleteStyle({ commit }, id) {
    const answer = await confirm(i18n.t('Are you sure you want to delete this style?'), {
      title: i18n.t('Delete style'),
      buttonTrueText: i18n.t('Delete'),
      buttonFalseText: i18n.t('Cancel')
    });

    if (answer) {
      commit('deleteStyle', id);
    }
  },

  cancelEditingStyle({ commit }) {
    commit('cancelEditingStyle');
  },

  addNewTag({ commit }) {
    commit('addNewTag');
  },

  editTag({ commit }, tag) {
    commit('editTag', tag);
  },

  saveTag({ commit, state, dispatch }) {
    const { tags } = state.editedOrganization.settings;
    for (const tag of tags) {
      if (tag.name === state.editedTag.name && tag.id !== state.editedTag.id) {
        dispatch('alert/error', i18n.t('Tag name already exists!'), { root: true });
        return;
      }
    }
    commit('saveTag');
  },

  async deleteTag({ commit }, id) {
    const answer = await confirm(i18n.t('Are you sure you want to delete this tag?'), {
      title: i18n.t('Delete tag'),
      buttonTrueText: i18n.t('Delete'),
      buttonFalseText: i18n.t('Cancel')
    });

    if (answer) {
      commit('deleteTag', id);
    }
  },

  cancelEditingTag({ commit }) {
    commit('cancelEditingTag');
  },

  setPageLoaded({ commit }, value) {
    commit('setPageLoaded', value);
  }
};

const mutations = {
  getAll(state, organizations) {
    state.organizations = organizations;
    state.isLoading = false;
  },

  isLoading(state) {
    state.isLoading = true;
  },

  addNew(state) {
    state.editedOrganization = {
      ...emptyOrganization,
      settings: { ...emptyOrganization.settings, rasterSources: [], tags: [] }
    };
    state.isNew = true;
  },

  edit(state, payload) {
    state.editedOrganization = { ...emptyOrganization, ...payload };
    state.isNew = false;
  },

  save(state) {
    state.isNew = false;
    state.editedOrganization = null;
  },

  delete(state) {
    state.editedOrganization = null;
    state.isNew = false;
  },

  cancel(state) {
    state.editedOrganization = null;
    state.isNew = false;
  },

  addNewRasterSource(state) {
    state.editedRasterSource = { ...emptyRasterSource };
    state.isNewRasterSource = true;
  },

  editRasterSource(state, payload) {
    state.editedRasterSource = { ...emptyRasterSource, ...payload };
    state.isNewRasterSource = false;
  },

  saveRasterSource(state) {
    const { rasterSources } = state.editedOrganization.settings;

    if (state.editedRasterSource._id) {
      const edited = rasterSources.find((rs) => rs._id === state.editedRasterSource._id);
      edited.name = state.editedRasterSource.name;
      edited.url = state.editedRasterSource.url;
      edited.description = state.editedRasterSource.description;
    } else {
      state.editedRasterSource._id = getNewUniqueId();
      state.editedRasterSource.isNew = true;
      rasterSources.push(state.editedRasterSource);
    }

    state.isNewRasterSource = false;
    state.editedRasterSource = undefined;
  },

  deleteRasterSource(state, id) {
    const { rasterSources } = state.editedOrganization.settings;
    state.editedOrganization.settings.rasterSources = rasterSources.filter((rs) => rs._id !== id);
    state.editedRasterSource = undefined;
  },

  cancelEditingRasterSource(state) {
    state.isNewRasterSource = false;
    state.editedRasterSource = undefined;
  },

  addNewStyle(state, layers) {
    state.editedStyle = { ...emptyStyle, layers: [...layers] };
    state.editedLayer = state.editedStyle.layers[0];
    state.isNewStyle = true;
  },

  editStyle(state, { style }) {
    state.editedStyle = {
      ...style,
      layers: [...style.layers.map((layer) => ({ ...layer }))]
    };
    state.editedLayer = state.editedStyle.layers[0];
    state.isNewStyle = false;
  },

  saveStyle(state) {
    const { styles } = state.editedOrganization.settings;

    if (state.editedStyle._id) {
      const edited = styles.find((style) => style._id === state.editedStyle._id);
      edited.name = state.editedStyle.name;
      edited.layers = state.editedStyle.layers;
    } else {
      state.editedStyle._id = getNewUniqueId();
      state.editedStyle.isNew = true;
      styles.push(state.editedStyle);
    }

    state.isNewStyle = false;
    state.editedStyle = undefined;
    state.editedLayer = undefined;
  },

  deleteStyle(state, id) {
    const { styles } = state.editedOrganization.settings;
    state.editedOrganization.settings.styles = styles.filter((style) => style._id !== id);
    state.editedStyle = undefined;
    state.editedLayer = undefined;
  },

  cancelEditingStyle(state) {
    state.isNewStyle = false;
    state.editedStyle = undefined;
    state.editedLayer = undefined;
  },

  addNewTag(state) {
    state.editedTag = { ...emptyTag };
    state.isNewTag = true;
  },

  editTag(state, payload) {
    state.editedTag = { ...emptyTag, ...payload };
    state.isNewTag = false;
  },

  saveTag(state) {
    const { tags } = state.editedOrganization.settings;

    if (state.editedTag.id) {
      const edited = tags.find((tag) => tag.id === state.editedTag.id);
      edited.name = state.editedTag.name;
      edited.base = state.editedTag.base;
      edited.top = state.editedTag.top;
    } else {
      state.editedTag.id = getNewUniqueId();
      state.editedTag.isNew = true;
      tags.push(state.editedTag);
    }

    state.isNewTag = false;
    state.editedTag = undefined;
  },

  deleteTag(state, id) {
    const { tags } = state.editedOrganization.settings;
    state.editedOrganization.settings.tags = tags.filter((tag) => tag.id !== id);
    state.editedTag = undefined;
  },

  cancelEditingTag(state) {
    state.isNewTag = false;
    state.editedTag = undefined;
  },

  generateSampleTags(state) {
    const sampleTags = [];
    sampleTags.push({
      ...emptyTag,
      name: 'Szék',
      base: 0,
      top: 0.5,
      isNew: true,
      id: getNewUniqueId()
    });
    sampleTags.push({
      ...emptyTag,
      name: 'Asztal',
      base: 0,
      top: 0.8,
      isNew: true,
      id: getNewUniqueId()
    });
    sampleTags.push({
      ...emptyTag,
      name: 'Pult',
      base: 0,
      top: 1.2,
      isNew: true,
      id: getNewUniqueId()
    });
    sampleTags.push({
      ...emptyTag,
      name: 'Szekrény',
      base: 0,
      top: 1.8,
      isNew: true,
      id: getNewUniqueId()
    });
    sampleTags.push({
      ...emptyTag,
      name: 'Fali szekrény',
      base: 1.7,
      top: 2.2,
      isNew: true,
      id: getNewUniqueId()
    });
    state.editedOrganization.settings.tags.push(...sampleTags);
  },

  editLayer(state, layerId) {
    state.editedLayer = state.editedStyle.layers.find((layer) => layer.id === layerId);
  },

  setLayerColor(state, { hex, alpha }) {
    state.editedLayer.color = hex;
    state.editedLayer.opacity = alpha;
  },

  setPageLoaded(state, value) {
    state.pageLoaded = value;
  },

  updateField
};

const getters = {
  organizations: (state) => state.organizations,
  editedOrganization: (state) => state.editedOrganization,
  editedRasterSource: (state) => state.editedRasterSource,
  editedStyle: (state) => state.editedStyle,
  editedTag: (state) => state.editedTag,
  editedLayer: (state) => state.editedLayer,
  isNew: (state) => state.isNew,
  isNewRasterSource: (state) => state.isNewRasterSource,
  isNewStyle: (state) => state.isNewStyle,
  isNewTag: (state) => state.isNewTag,
  isLoading: (state) => state.isLoading,
  pageLoaded: (state) => state.pageLoaded,
  getField
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
