<template>
  <div @click="handleClick">
    <div
      ref="me"
      id="me"
      contenteditable
      @input.stop="handleInput"
      @blur="handleDone"
      @keydown.enter="handleDone"
      @keypress.stop="handleKeyPress"
      v-text="value"
      v-show="editing"
    ></div>
    <gli-label v-model="value" width="250px" :class="{ editable: canEdit }" v-if="!editing" />
  </div>
</template>
<style scoped>
.editable:hover:after {
  content: '✎';
  position: relative;
  right: -3px;
}

[contenteditable]:focus {
  outline: 1px solid #ddd;
  padding: 4px;
}

.gli-label {
  text-transform: none;
}

#me {
  text-transform: none;
}
</style>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'GliEditable',
  props: {
    value: { type: String, default: '' },
    maxLength: { type: Number, default: 500 }
  },
  data() {
    return {
      editing: false,
      caretPosition: 0
    };
  },
  computed: {
    ...mapGetters('account', ['canEdit'])
  },
  updated() {
    if (this.editing) {
      const selection = window.getSelection();
      const range = document.createRange();
      const element = document.getElementById('me');
      selection.removeAllRanges();
      range.setStart(element.childNodes[0], this.caretPosition);
      range.collapse(true);
      selection.addRange(range);
    }
  },
  methods: {
    handleInput(e) {
      this.$emit('siteNameChanged', e.target.innerText);
      this.caretPosition = window.getSelection().baseOffset;
    },
    handleKeyPress(e) {
      // console.log('handleKeyPress');
      if (e.target.innerText.length >= this.maxLength) {
        e.preventDefault();
        return;
      }
    },
    handleClick() {
      if (this.canEdit) {
        this.editing = true;
        this.caretPosition = 0;
        setTimeout(() => {
          this.$refs.me.focus();
        }, 10);
      }
    },
    handleDone(e) {
      this.$emit('input', e.target.innerText);
      this.editing = false;
    }
  }
};
</script>
