import ApiService from '../services/ApiService';
import constants from '~/shared/constants';
import i18n from '../i18n';

const emptyState = {
  routeFrom: undefined,
  routeTo: undefined,
  route: undefined,
  vtr: undefined,
  wheelchair: false
};

const state = {
  ...emptyState
};

const actions = {
  routeFrom({ commit, dispatch }, payload) {
    commit('routeFrom', payload);
    dispatch('calculateRoute');
  },

  routeTo({ commit, dispatch }, payload) {
    commit('routeTo', payload);
    dispatch('calculateRoute');
  },

  setWheelchair({ commit, dispatch }, payload) {
    commit('setWheelchair', payload);
    dispatch('calculateRoute');
  },

  setVTR({ commit, dispatch }, payload) {
    commit('setVTR', payload);
    dispatch('calculateRoute');
  },

  async calculateRoute({ commit, dispatch, state, rootGetters }) {
    if (state.routeFrom && state.routeTo) {
      const requestData = {
        personType: state.vtr ? state.vtr : 'NONE',
        attributes: {
          personHasWheelChair: state.wheelchair
        },
        waypoints: [
          { displayLevel: state.routeFrom.displayLevel, position: state.routeFrom.coordinates },
          { displayLevel: state.routeTo.displayLevel, position: state.routeTo.coordinates }
        ]
      };
      const { data } = await ApiService.calculateRoute(
        rootGetters['site/editedSite'].id,
        requestData
      );

      if (data.error && data.error.message) {
        if (data.error.code === 1) {
          data.error.message = i18n.t('No route found.');
        }
        dispatch('alert/error', data.error.message, { root: true });
      } else {
        commit('calculateRoute', data);
      }
    }
  },

  reset({ commit }) {
    commit('reset');
  }
};

const mutations = {
  reset(state) {
    Object.assign(state, emptyState);
  },

  setWheelchair(state, payload) {
    state.wheelchair = payload;
  },

  setVTR(state, payload) {
    state.vtr = payload;
  },

  routeFrom(state, payload) {
    state.routeFrom = payload;
    if (!payload) {
      state.route = undefined;
    }
  },

  routeTo(state, payload) {
    state.routeTo = payload;
    if (!payload) {
      state.route = undefined;
    }
  },

  calculateRoute(state, payload) {
    state.route = payload;
  },

  clearRouting(state) {
    state.routeFrom = undefined;
    state.routeTo = undefined;
    state.route = undefined;
  }
};

const getters = {
  wheelchair: (state) => state.wheelchair,
  vtr: (state) => state.vtr,
  routeFrom: (state) => state.routeFrom,
  routeTo: (state) => state.routeTo,

  routingFeatures: (state, getters, rootState, rootGetters) => {
    const routingFeatures = [];

    if (state.routeFrom && state.routeFrom.levelId === rootGetters['level/editedLevelId']) {
      routingFeatures.push({
        type: 'Feature',
        properties: { _type: constants.FEATURE_TYPES.ROUTE_FROM, icon: 'mdi_human-male' },
        geometry: {
          type: 'Point',
          coordinates: state.routeFrom.coordinates
        }
      });
    }

    if (state.routeTo && state.routeTo.levelId === rootGetters['level/editedLevelId']) {
      routingFeatures.push({
        type: 'Feature',
        properties: { _type: constants.FEATURE_TYPES.ROUTE_TO, icon: 'mdi_flag' },
        geometry: {
          type: 'Point',
          coordinates: state.routeTo.coordinates
        }
      });
    }

    if (state.route) {
      let levelFeatures = state.route.features.filter(
        (feature) => feature.properties.displayLevel === rootGetters['level/editedLevelOrder']
      );

      levelFeatures.forEach(
        (feature) => (feature.properties._type = constants.FEATURE_TYPES.ROUTE)
      );

      routingFeatures.push(...levelFeatures);
    }

    return routingFeatures;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
