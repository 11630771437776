import Vue from 'vue';
import constants from '~/shared/constants';
import confirm from '../helpers/confirm';
import i18n from '../i18n';

const emptyState = {
  imagesByLevel: {},
  currentImageUrl: undefined,
  currentImageMeta: undefined,
  isLayerVisible: true,
  transparency: 0,
  imageConvertInProgress: false,
  editImageOnSetup: false
};

const state = {
  ...emptyState
};

const actions = {
  async uploadImage({ commit, dispatch }, { image, meta }) {
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.onerror = (error) => reject(error);
      });
    let base64 = await toBase64(image);
    commit('setImage', { base64, meta });
    dispatch('history/add', constants.OPERATIONS.IMAGE.UPLOAD, { root: true });
  },

  async setImageMeta({ commit, dispatch }, payload) {
    commit('setMeta', payload);
    if (!payload.skipHistory) {
      dispatch('history/add', constants.OPERATIONS.IMAGE.MODIFY, { root: true });
    }
  },

  async deleteImage({ commit, dispatch }, levelId) {
    const answer = await confirm(i18n.t('Are you sure you want to delete this image layer?'), {
      title: i18n.t('Delete floorplan image layer'),
      buttonTrueText: i18n.t('Delete'),
      buttonFalseText: i18n.t('Cancel')
    });

    if (answer) {
      commit('unsetImage', levelId);
      dispatch('history/add', constants.OPERATIONS.IMAGE.DELETE, { root: true });
    }
  },

  refresh({ commit, rootGetters }, payload) {
    commit('refresh', payload);
    const levelId = rootGetters['level/editedLevelId'];
    const levelImage = state.imagesByLevel[levelId];
    if (levelImage) {
      commit(
        'image/setImage',
        {
          base64: state.imagesByLevel[levelId].base64,
          meta: state.imagesByLevel[levelId].meta
        },
        { root: true }
      );
    } else {
      commit('image/unsetImage', undefined, { root: true });
    }
  },

  loadFromHistory({ commit, rootGetters }, payload) {
    commit('loadFromHistory', payload);
    const levelId = rootGetters['level/editedLevelId'];
    const levelImage = state.imagesByLevel[levelId];
    if (levelImage) {
      commit(
        'image/setImage',
        {
          base64: state.imagesByLevel[levelId].base64,
          meta: state.imagesByLevel[levelId].meta
        },
        { root: true }
      );
    } else {
      commit('image/unsetImage', undefined, { root: true });
    }
  },
  reset({ commit }) {
    commit('reset');
  },

  changeEditImageOnSetup({ commit }, edit) {
    commit('changeEditImageOnSetup', edit);
  },

  setImageConvertInProgress({ commit }, value) {
    commit('setImageConvertInProgress', value);
  }
};

const mutations = {
  reset(state) {
    Object.assign(state, emptyState);
  },
  refresh(state, payload) {
    state.imagesByLevel = {};
    const images = [...payload];
    for (const image of images) {
      Vue.set(state.imagesByLevel, image._levelId, {
        base64: image.buffer,
        meta: {
          levelId: image._levelId,
          size: image.size,
          coordinates: image.coordinates,
          mimeType: image.mimeType
        }
      });
    }
  },

  loadFromHistory(state, payload) {
    state.imagesByLevel = payload;
  },

  setLayerVisibility(state, payload) {
    state.isLayerVisible = payload;
  },

  setLayerTransparency(state, payload) {
    state.transparency = payload;
  },

  setImage(state, { base64, meta }) {
    Vue.set(state.imagesByLevel, meta.levelId, {
      base64,
      meta
    });
    state.currentImageMeta = meta;
    state.currentImageUrl = `data:${meta.mimeType}${meta.levelId};base64,${base64}`;
  },

  changeLevel(state, levelId) {
    if (state.imagesByLevel[levelId]) {
      const image = state.imagesByLevel[levelId];
      state.currentImageMeta = image.meta;
      state.currentImageUrl = `data:${image.meta.mimeType}${levelId};base64,${image.base64}`;
    } else {
      state.currentImageUrl = undefined;
      state.currentImageMeta = undefined;
    }
    state.transparency = 0;
  },

  unsetImage(state, levelId) {
    Vue.delete(state.imagesByLevel, levelId);
    state.currentImageMeta = undefined;
    state.currentImageUrl = undefined;
  },

  setMeta(state, meta) {
    Vue.set(state.imagesByLevel[meta.levelId], 'meta', meta);
    state.currentImageMeta = meta;
  },

  duplicateLevel(state, { oldLevelId, newLevelId }) {
    if (state.imagesByLevel[oldLevelId]) {
      Vue.set(state.imagesByLevel, newLevelId, {
        ...state.imagesByLevel[oldLevelId],
        meta: { ...state.imagesByLevel[oldLevelId].meta, levelId: newLevelId }
      });
    }
  },

  deleteLevel(state, levelId) {
    Vue.delete(state.imagesByLevel, levelId);
  },

  setImageConvertInProgress(state, value) {
    state.imageConvertInProgress = value;
  },

  changeEditImageOnSetup(state, edit) {
    state.editImageOnSetup = edit;
  }
};

const getters = {
  currentImageMeta: (state) => state.currentImageMeta,
  currentImageUrl: (state) => state.currentImageUrl,
  isLayerVisible: (state) => state.isLayerVisible,
  imagesByLevel: (state) => state.imagesByLevel,
  imageTransparency: (state) => state.transparency,
  imageConvertInProgress: (state) => state.imageConvertInProgress,
  editImageOnSetup: (state) => state.editImageOnSetup
};

export const image = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
