import { getField, updateField } from 'vuex-map-fields';
import constants from '~/shared/constants';
import { getNewUniqueId } from '~/shared/utils';

// these fields need to exist to become reactive
const emptyAnnotation = {
  feature: {
    type: 'Feature',
    properties: {
      name: null,
      _type: constants.FEATURE_TYPES.ANNOTATION
    },
    geometry: null
  },
  id: null,
  levelId: null
};

const emptyState = {
  editedAnnotation: null,
  isNew: false
};

const state = {
  ...emptyState
};

const actions = {
  async edit({ commit, rootGetters }, annotationId) {
    const featuresByLevel = rootGetters['feature/featuresByLevel'];
    const editedLevelId = rootGetters['level/editedLevelId'];
    commit(
      'edit',
      featuresByLevel[editedLevelId].find((feature) => feature.id === annotationId)
    );
  },

  addNew({ commit, rootGetters }, payload) {
    payload.levelId = rootGetters['level/editedLevelId'];
    payload.id = getNewUniqueId();
    commit('addNew', payload);
  },

  async save({ commit, dispatch, rootGetters }) {
    let operation = constants.OPERATIONS.FLOORPLAN.ADD_ANNOTATION;
    if (state.isNew) {
      commit('feature/addFeature', state.editedAnnotation, { root: true });
    } else {
      operation = constants.OPERATIONS.FLOORPLAN.MODIFY_ANNOTATION;
      commit('feature/editFeature', state.editedAnnotation, { root: true });
    }
    const editedLevelId = rootGetters['level/editedLevelId'];
    commit('feature/updateCurrentFeatures', editedLevelId, { root: true });
    commit('reset');
    dispatch('history/add', operation, { root: true });
    dispatch('alert/success', null, { root: true });
  },

  reset({ commit }) {
    commit('reset');
  }
};

const mutations = {
  reset(state) {
    Object.assign(state, emptyState);
  },
  addNew(state, payload) {
    state.editedAnnotation = {
      ...emptyAnnotation,
      id: payload.id,
      levelId: payload.levelId,
      feature: {
        ...emptyAnnotation.feature,
        properties: {
          ...emptyAnnotation.feature.properties,
          ...payload.properties,
          id: payload.id,
          levelId: payload.levelId
        },
        geometry: payload.geometry
      }
    };
    state.isNew = true;
  },

  edit(state, payload) {
    state.editedAnnotation = { ...emptyAnnotation, ...payload };
    state.isNew = false;
  },

  updateField
};

const getters = {
  editedAnnotation: (state) => state.editedAnnotation,
  isNew: (state) => state.isNew,
  getField
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
