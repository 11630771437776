const config = {
  VUE_APP_BASE_PATH: '$VUE_APP_BASE_PATH',
  VUE_APP_API_SERVICE_URL: '$VUE_APP_API_SERVICE_URL',
  VUE_APP_I18N_LOCALE: '$VUE_APP_I18N_LOCALE',
  VUE_APP_I18N_FALLBACK_LOCALE: '$VUE_APP_I18N_FALLBACK_LOCALE',
  VUE_APP_TILE_URL: '$VUE_APP_TILE_URL',
  VUE_APP_LDM_TILE_SERVICE_URL: '$VUE_APP_LDM_TILE_SERVICE_URL',
  VUE_APP_MAPBOX_ACCESS_TOKEN: '$VUE_APP_MAPBOX_ACCESS_TOKEN',
  VUE_APP_BRANDING: '$VUE_APP_BRANDING'
};

const _enum = {
  VUE_APP_BASE_PATH: 'VUE_APP_BASE_PATH',
  VUE_APP_API_SERVICE_URL: 'VUE_APP_API_SERVICE_URL',
  VUE_APP_I18N_LOCALE: 'VUE_APP_I18N_LOCALE',
  VUE_APP_I18N_FALLBACK_LOCALE: 'VUE_APP_I18N_FALLBACK_LOCALE',
  VUE_APP_TILE_URL: 'VUE_APP_TILE_URL',
  VUE_APP_LDM_TILE_SERVICE_URL: 'VUE_APP_LDM_TILE_SERVICE_URL',
  VUE_APP_MAPBOX_ACCESS_TOKEN: 'VUE_APP_MAPBOX_ACCESS_TOKEN',
  VUE_APP_BRANDING: 'VUE_APP_BRANDING'
};

const getEnv = (key) => {
  const value = config[key];
  if (value.startsWith('$VUE_APP_')) {
    // value was not replaced, it seems we are not in docker
    // remove $ and get current value from process.env
    const envName = value.substr(1);
    const envValue = process.env[envName];
    if (envValue) {
      return envValue;
    } else {
      return undefined;
    }
  }
  // value was replaced in docker-entrypoint.sh
  return value;
};

export default {
  enum: _enum,
  getEnv,
  getBaseApiServiceUrl: () => {
    return getEnv(_enum.VUE_APP_API_SERVICE_URL) || `http://localhost:8081`;
  },
  getLDMTileServiceUrl: () => {
    return (
      getEnv(_enum.VUE_APP_LDM_TILE_SERVICE_URL) ||
      `https://demo.mapcat.com/ldm-tile-service/?tilesetId=`
    );
  }
};
