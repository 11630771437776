import constants from '~/shared/constants';

const emptyEntryPoint = {
  id: null,
  levelId: null,
  feature: {
    type: 'Feature',
    geometry: {
      coordinates: null,
      type: 'Point'
    },
    properties: {
      _type: constants.FEATURE_TYPES.JUNCTION,
      type: constants.JUNCTION_TYPES.ENTRY_POINT,
      levelId: null,
      networkTransition: true,
      siteTransition: false
    }
  }
};

const state = {
  editedEntryPoint: null,
  isNew: false,
  networkTransition: true,
  siteTransition: false
};

const actions = {
  add({ commit }, { feature }) {
    commit('add', feature);
    commit('clear');
  },

  edit({ commit }, { feature }) {
    commit('edit', feature);
  },

  async delete({ commit, dispatch, rootGetters }, { entryPoint }) {
    const netSegConnectedToElevator = await dispatch(
      'feature/networkSegmentIsConnected',
      {
        junctionId: entryPoint.id,
        levelId: entryPoint.feature.properties.levelId
      },
      { root: true }
    );
    if (netSegConnectedToElevator) {
      entryPoint.feature.properties.type = constants.JUNCTION_TYPES.NORMAL;
      commit('feature/editFeature', entryPoint, { root: true });
    } else {
      commit('feature/deleteFeature', entryPoint.id, { root: true });
    }
    const editedLevelId = rootGetters['level/editedLevelId'];
    commit('feature/updateCurrentFeatures', editedLevelId, { root: true });
    commit('clear');
    return netSegConnectedToElevator ? entryPoint : undefined;
  },

  setTransition({ commit }, type) {
    commit('setTransition', type);
  },

  clear({ commit }) {
    commit('clear');
  }
};

const mutations = {
  add(state, feature) {
    state.editedEntryPoint = { ...emptyEntryPoint };
    state.editedEntryPoint.id = feature.id;
    state.editedEntryPoint.levelId = feature.properties.levelId;
    state.editedEntryPoint.feature.geometry.coordinates = [...feature.geometry.coordinates];
    state.editedEntryPoint.feature.properties.levelId = feature.properties.levelId;
    state.editedEntryPoint.feature.properties.networkTransition = state.networkTransition;
    state.editedEntryPoint.feature.properties.siteTransition = state.siteTransition;
    state.isNew = true;
  },

  edit(state, feature) {
    state.editedEntryPoint = { ...emptyEntryPoint };
    state.editedEntryPoint.id = feature.id;
    state.editedEntryPoint.levelId = feature.properties.levelId;
    state.editedEntryPoint.feature.geometry.coordinates = [...feature.geometry.coordinates];
    state.editedEntryPoint.feature.properties.levelId = feature.properties.levelId;
    state.editedEntryPoint.feature.properties.networkTransition =
      feature.properties.networkTransition;
    state.editedEntryPoint.feature.properties.siteTransition = feature.properties.siteTransition;
    state.networkTransition = feature.properties.networkTransition;
    state.siteTransition = feature.properties.siteTransition;
    state.isNew = false;
  },

  setTransition(state, type) {
    if (type === constants.EXCHANGE_POINT_TYPES.NETWORK_TRANSITION) {
      state.networkTransition = !state.networkTransition;
    } else if (type === constants.EXCHANGE_POINT_TYPES.SITE_TRANSITION) {
      state.siteTransition = !state.siteTransition;
    }
    if (state.editedEntryPoint) {
      state.editedEntryPoint.feature.properties.networkTransition = state.networkTransition;
      state.editedEntryPoint.feature.properties.siteTransition = state.siteTransition;
    }
  },

  clear(state) {
    state.editedEntryPoint = null;
    state.isNew = false;
    state.networkTransition = true;
    state.siteTransition = false;
  }
};

const getters = {
  networkTransition: (state) => state.networkTransition,
  siteTransition: (state) => state.siteTransition,
  isNew: (state) => state.isNew,
  editedEntryPoint: (state) => state.editedEntryPoint
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
