import proj4 from 'proj4';
import constants from '~/shared/constants';

const state = {
  mouseCoords: undefined,
  edgeProps: undefined,
  snappingCoords: undefined,
  currentMainMode: constants.MAIN_MODES.STATIC,
  currentDrawMode: constants.DRAW_MODES.STATIC,
  extrusion: false,
  mapLoaded: false,
  showMapSettings: false,
  keepFacilityAspectRatio: true,
  skipApplyStyle: false
};

const actions = {
  setCurrentMainMode({ commit }, mainMode) {
    commit('setCurrentMainMode', mainMode);
  },

  setCurrentDrawMode({ commit }, drawMode) {
    commit('setCurrentDrawMode', drawMode);
  },

  showMapSettings({ commit }) {
    commit('showMapSettings');
  },

  hideMapSettings({ commit }) {
    commit('hideMapSettings');
  },

  setSkipApplyStyle({ commit }, setTo) {
    commit('setSkipApplyStyle', setTo);
  }
};

const mutations = {
  setCurrentMainMode(state, mode) {
    state.currentMainMode = mode;
  },

  setCurrentDrawMode(state, mode) {
    state.currentDrawMode = mode;
  },

  updateMouseCoords(state, payload) {
    state.mouseCoords = payload;
  },
  updateEdgeProps(state, payload) {
    if (payload) {
      state.edgeProps = payload;
    } else {
      state.edgeProps = undefined;
    }
  },
  updateSnappingCoords(state, payload) {
    if (payload) {
      state.snappingCoords = payload;
    } else {
      state.snappingCoords = undefined;
    }
  },
  toggleExtrusion(state, setTo) {
    if (setTo !== undefined) {
      state.extrusion = setTo;
    } else {
      state.extrusion = !state.extrusion;
    }
  },
  mapLoaded(state) {
    state.mapLoaded = true;
  },

  showMapSettings(state) {
    state.showMapSettings = true;
  },

  hideMapSettings(state) {
    state.showMapSettings = false;
  },

  changekeepFacilityAspectRatio(state) {
    state.keepFacilityAspectRatio = !state.keepFacilityAspectRatio;
  },

  changeStyle(state, style) {
    state.style = style;
  },

  setSkipApplyStyle(state, setTo) {
    state.skipApplyStyle = setTo;
  }
};

const getters = {
  WGS84: (state) => {
    return state.mouseCoords;
  },

  EOV: (state) => {
    return state.mouseCoords ? proj4(constants.EOV_PROJECTION, state.mouseCoords) : undefined;
  },

  snappingWGS84: (state) => {
    return state.snappingCoords;
  },

  snappingEOV: (state) => {
    return state.snappingCoords ? proj4(constants.EOV_PROJECTION, state.snappingCoords) : undefined;
  },

  editorMode: (state) => state.currentMainMode !== constants.MAIN_MODES.STATIC,

  currentMainMode: (state) => state.currentMainMode,

  currentDrawMode: (state) => state.currentDrawMode,

  extrusion: (state) => {
    return state.extrusion;
  },
  mapLoaded: (state) => {
    return state.mapLoaded;
  },
  showMapSettings: (state) => state.showMapSettings,
  keepFacilityAspectRatio: (state) => state.keepFacilityAspectRatio,
  skipApplyStyle: (state) => state.skipApplyStyle
};

export const status = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
