import ApiService from '../services/ApiService';
import { getField, updateField } from 'vuex-map-fields';
import confirm from '../helpers/confirm';
import i18n from '../i18n';

const emptyUser = {
  id: null,
  username: null,
  password: '',
  firstName: null,
  lastName: null,
  email: null,
  role: null,
  organizationId: null
};

const state = {
  users: [],
  editedUser: null,
  isNew: false
};

const actions = {
  async getAll({ commit }) {
    const { data } = await ApiService.getAllUsers();
    commit('getAll', data);
  },

  addNew({ commit }) {
    commit('addNew');
  },

  async save({ commit, dispatch }) {
    try {
      if (state.isNew) {
        await ApiService.registerUser(state.editedUser);
        commit('save');
        dispatch('alert/success', i18n.t('The registration was successful.'), { root: true });
        dispatch('getAll');
      } else {
        await ApiService.updateUser(state.editedUser);
        commit('save');
        dispatch('alert/success', i18n.t('The user was updated successfully.'), { root: true });
        dispatch('getAll');
      }
    } catch (error) {
      dispatch('alert/error', error, { root: true });
    }
  },

  edit({ commit }, userId) {
    const user = state.users.filter((user) => userId === user._id)[0];
    commit('edit', user);
  },

  async delete({ commit, dispatch }, id) {
    const answer = await confirm(i18n.t('Are you sure you want to delete the selected user?'), {
      title: i18n.t('Delete user'),
      buttonTrueText: i18n.t('Delete'),
      buttonFalseText: i18n.t('Cancel')
    });
    if (answer) {
      try {
        await ApiService.deleteUser(id);
        commit('delete');
        dispatch('alert/success', i18n.t('The user was deleted successfully.'), { root: true });
        dispatch('getAll');
      } catch (error) {
        dispatch('alert/error', error, { root: true });
      }
    }
  },

  cancel({ commit }) {
    commit('cancel');
  }
};

const mutations = {
  getAll(state, users) {
    state.users = users;
  },

  addNew(state) {
    state.editedUser = { ...emptyUser };
    state.isNew = true;
  },

  edit(state, payload) {
    state.editedUser = { ...emptyUser, ...payload };
    state.isNew = false;
  },

  save(state) {
    state.isNew = false;
    state.editedUser = null;
  },

  delete(state) {
    state.editedUser = null;
    state.isNew = false;
  },

  cancel(state) {
    state.editedUser = null;
    state.isNew = false;
  },

  updateField
};

const getters = {
  users: (state) => state.users,
  editedUser: (state) => state.editedUser,
  isNew: (state) => state.isNew,
  getField
};

export const users = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
