import Api from './AxiosInstance';
import { Venue } from '@/types/types';
import { sleep } from '~/shared/helpers/sleep';
import constants from '~/shared/constants';

const PATH = '/api/venues';

export default {
  getVenues() {
    return Api.get<Venue[]>(PATH);
  },

  getVenue(id: string) {
    return Api.get<Venue>(PATH + `/${id}`);
  },

  getVenueData(id: string) {
    return Api.get<Venue['data']>(PATH + `/${id}/connection`);
  },

  publish(venueId: string) {
    return Api.put(PATH + `/publish/${venueId}`);
  },

  unpublish(venueId: string) {
    return Api.delete(PATH + `/unpublish/${venueId}`);
  },

  async status(venueId: string, version: string) {
    let status;
    do {
      await sleep(5000);
      const { data } = await Api.get(PATH + `/status/${venueId}/${version}`);
      status = data;
    } while (status === constants.PUBLISH_STATUS.PUBLISHING);
    return status;
  },

  saveVenue(venue: Venue) {
    if (venue.id) {
      return Api.put(PATH + `/${venue.id}`, venue);
    } else {
      return Api.post(PATH, venue);
    }
  },

  deleteVenue(id: string) {
    return Api.delete(PATH + `/${id}`);
  }
};
