import { Organization } from '@/types/types';
import Api from './AxiosInstance';

const PATH = '/api/organizations';

export default {
  getOrganizations() {
    return Api.get<Organization[]>(PATH);
  },

  getOrganization(id: string) {
    return Api.get<Organization>(PATH + `/${id}`);
  },

  saveOrganization(organization: Organization) {
    if (organization.id) {
      return Api.put(PATH + `/${organization.id}`, organization);
    } else {
      return Api.post(PATH, organization);
    }
  },

  deleteOrganization(id: string) {
    return Api.delete(PATH + `/${id}`);
  }
};
