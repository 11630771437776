import { ActionTree, Commit, Dispatch, GetterTree, MutationTree } from 'vuex';
import constants from '~/shared/constants';

// the images are stored separately and every history item only contains
// an index pointer to the image in the imageStore array
type State = {
  history: any[];
  imageStore: string[];
  position: number;
};

const emptyState: State = {
  history: [],
  position: -1,
  imageStore: []
};

const state: State = {
  ...emptyState
};

const loadFromHistory = (historyItem: any, commit: Commit, dispatch: Dispatch) => {
  Object.entries<any>(historyItem.imagesByLevel).forEach(([levelId, { base64Index }]) => {
    historyItem.imagesByLevel[levelId].base64 = state.imageStore[base64Index];
  });
  Object.entries<any>(historyItem.customImagesByLevel).forEach(([, imagesOnLevel]) => {
    for (const image of imagesOnLevel) {
      image.base64 = state.imageStore[image.base64Index];
    }
  });

  // deselect all network segments
  dispatch('feature/updateSelectedNetworkSegmentIds', [], { root: true });

  commit('level/loadFromHistory', historyItem.levels, { root: true });
  commit('site/loadFromHistory', historyItem.siteName, { root: true });
  dispatch('image/loadFromHistory', historyItem.imagesByLevel, { root: true });
  dispatch('customImage/loadFromHistory', historyItem.customImagesByLevel, { root: true });
  dispatch(
    'feature/loadFromHistory',
    { features: historyItem.featuresByLevel, exchangePoints: historyItem.exchangePoints },
    { root: true }
  );
};

const actions: ActionTree<State, any> = {
  add({ commit, rootGetters }, operation) {
    const lastHistoryItem = rootGetters['history/current'];
    const payload = {
      siteName: rootGetters['site/editedSite'].name,
      featuresByLevel: rootGetters['feature/featuresByLevel'],
      imagesByLevel: rootGetters['image/imagesByLevel'],
      customImagesByLevel: rootGetters['customImage/imagesByLevel'],
      levels: rootGetters['level/levels'],
      operation,
      exchangePoints: rootGetters['feature/exchangePoints']
    };

    if (
      operation === constants.OPERATIONS.SITE.RENAME &&
      lastHistoryItem &&
      lastHistoryItem.operation === constants.OPERATIONS.SITE.RENAME
    ) {
      commit('undo');
      commit('removeLast');
    }

    const payloadDeepCopy = JSON.parse(JSON.stringify(payload));

    commit('add', payloadDeepCopy);
  },

  undo({ commit, dispatch, state }) {
    const prevHistoryItem = JSON.parse(state.history[state.position - 1]);
    loadFromHistory(prevHistoryItem, commit, dispatch);
    commit('undo');
  },

  redo({ commit, dispatch }) {
    const nextHistoryItem = JSON.parse(state.history[state.position + 1]);
    loadFromHistory(nextHistoryItem, commit, dispatch);
    commit('redo');
  },

  undoAndRemoveLast({ commit, dispatch }) {
    dispatch('undo');
    commit('removeLast');
  },

  loadCurrent({ commit, dispatch }) {
    const currentHistoryItem = JSON.parse(state.history[state.position]);
    loadFromHistory(currentHistoryItem, commit, dispatch);
    dispatch('stairs/reset', null, { root: true });
    commit('loadCurrent');
  },

  /** Empties history */
  clear({ commit }) {
    commit('clear');
  },

  /** Resets history after save, retains the last state */
  reset({ commit, getters }) {
    commit('reset', getters.current);
  },

  resetToEmpty({ commit }) {
    commit('resetToEmpty');
  }
};

const mutations: MutationTree<State> = {
  add(state, payload) {
    if (state.position < state.history.length - 1) {
      state.history = state.history.slice(0, state.position + 1);
      state.position = state.history.length - 1;
    }

    // store images separately
    Object.keys(payload.imagesByLevel).forEach((levelId) => {
      const levelImage = payload.imagesByLevel[levelId];
      if (levelImage.base64) {
        let index = state.imageStore.indexOf(levelImage.base64);

        if (index === -1) {
          state.imageStore = [...state.imageStore, levelImage.base64];
          index = state.imageStore.length - 1;
        }

        levelImage.base64Index = index;
        levelImage.base64 = undefined;
      }
    });

    Object.keys(payload.customImagesByLevel).forEach((levelId) => {
      const imagesOnLevel = payload.customImagesByLevel[levelId];
      for (const image of imagesOnLevel) {
        if (image.base64) {
          let index = state.imageStore.indexOf(image.base64);

          if (index === -1) {
            state.imageStore = [...state.imageStore, image.base64];
            index = state.imageStore.length - 1;
          }

          image.base64Index = index;
          image.base64 = undefined;
        }
      }
    });

    state.history.push(JSON.stringify(payload));
    state.position++;
  },

  undo(state) {
    state.position--;
  },

  redo(state) {
    state.position++;
  },

  removeLast(state) {
    state.history.pop();
  },

  loadCurrent() {},

  clear(state) {
    state.history = [];
    state.imageStore = [];
    state.position = -1;
  },

  reset(state, currentState) {
    currentState.operation = undefined;
    state.history = [JSON.stringify(currentState)];
    state.position = 0;
  },

  resetToEmpty(state) {
    Object.assign(state, emptyState);
  }
};

const getters: GetterTree<State, any> = {
  current: (state) => {
    if (state.position < 0) {
      return null;
    }

    const historyItem = JSON.parse(state.history[state.position]);
    Object.entries<any>(historyItem.imagesByLevel).forEach(([levelId, { base64Index }]) => {
      historyItem.imagesByLevel[levelId].base64 = state.imageStore[base64Index];
    });

    Object.entries<any>(historyItem.customImagesByLevel).forEach(([, imagesOnLevel]) => {
      for (const image of imagesOnLevel) {
        image.base64 = state.imageStore[image.base64Index];
      }
    });

    return historyItem;
  },
  undoCount: (state) => state.position,
  redoCount: (state) => state.history.length - state.position - 1,
  undoOperation: (state, getters) => getters.current?.operation,
  redoOperation: (state) =>
    (state.position < state.history.length - 1
      ? JSON.parse(state.history[state.position + 1])
      : null
    )?.operation
};

export const history = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
