import ApiService from '@/services/ApiService';
import constants from '~/shared/constants';
import confirm from '../helpers/confirm';
import i18n from '../i18n';

const emptyState = {
  levels: [],
  editedLevel: null,
  numberOfNewLevels: 0
};

const state = {
  ...emptyState
};

const actions = {
  addNew({ commit, dispatch }) {
    const newId = `${state.levels.length}x${state.numberOfNewLevels}`;
    commit('addNew', newId);
    dispatch('feature/addNewLevel', newId, { root: true });
    dispatch('history/add', constants.OPERATIONS.LEVEL.ADD, { root: true });
  },

  async export({ commit, rootGetters }, mode) {
    const siteId = rootGetters['site/editedSite'].id;
    const levelId = rootGetters['level/editedLevelId'];

    const { data } = await ApiService.exportLevel({ siteId, levelId, mode });

    let dataUrl;
    let extension;
    if (mode === constants.EXPORT_TYPES.GEOJSON) {
      extension = 'json';
      dataUrl =
        'data:text/json;charset=utf-8,' +
        encodeURIComponent(JSON.stringify(data.featureCollection));
    }

    if (mode === constants.EXPORT_TYPES.PNG) {
      extension = 'png';
      dataUrl = 'data:image/png;base64,' + data.base64;
    }

    if (mode === constants.EXPORT_TYPES.TIFF) {
      extension = 'tiff';
      dataUrl = 'data:image/tiff;base64,' + data.base64;
    }

    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', dataUrl);
    downloadAnchorNode.setAttribute('download', `${data.name}.${extension}`);
    downloadAnchorNode.click();

    commit('export');
  },

  async delete({ commit, dispatch }, levelIndex) {
    const answer = await confirm(i18n.t('Are you sure you want to delete this level?'), {
      title: i18n.t('Delete level'),
      buttonTrueText: i18n.t('Delete'),
      buttonFalseText: i18n.t('Cancel')
    });

    if (answer) {
      const levelId = state.levels[levelIndex].id;

      commit('image/deleteLevel', levelId, { root: true });
      commit('customImage/deleteLevel', levelId, { root: true });
      await dispatch('feature/deleteLevel', levelId, { root: true });
      commit('delete', levelId);

      const needChangeEditedLevel = state.editedLevel && state.editedLevel.id === levelId;
      if (needChangeEditedLevel) {
        dispatch('change', state.levels[0].id);
      } else {
        commit('feature/updateCurrentFeatures', state.editedLevel.id, { root: true });
      }

      dispatch('history/add', constants.OPERATIONS.LEVEL.DELETE, { root: true });
    }
  },

  change({ commit }, levelId) {
    commit('change', levelId);
    commit('image/changeLevel', levelId, { root: true });
    commit('customImage/changeLevel', levelId, { root: true });
    commit('feature/updateCurrentFeatures', levelId, { root: true });
  },

  duplicate({ commit, dispatch }, levelIndex) {
    const newLevel = {
      id: `${state.levels.length}x${state.numberOfNewLevels}`,
      name: `${state.levels[levelIndex].name} - copy`,
      isStreetLevel: false
    };
    commit(
      'image/duplicateLevel',
      {
        oldLevelId: state.levels[levelIndex].id,
        newLevelId: newLevel.id
      },
      { root: true }
    );
    commit(
      'customImage/duplicateLevel',
      {
        oldLevelId: state.levels[levelIndex].id,
        newLevelId: newLevel.id
      },
      { root: true }
    );
    commit(
      'feature/duplicateLevel',
      {
        oldLevelId: state.levels[levelIndex].id,
        newLevelId: newLevel.id
      },
      { root: true }
    );
    commit('duplicate', { levelIndex, newLevel });
    dispatch('history/add', constants.OPERATIONS.LEVEL.DUPLICATE, { root: true });
  },

  order({ commit, dispatch }, payload) {
    commit('order', payload.levels);
    dispatch('stairs/updateStairsDirections', payload.movedLevelId, { root: true });
    dispatch('elevator/updateExchangePoints', payload.movedLevelId, { root: true });
    dispatch('history/add', constants.OPERATIONS.LEVEL.ORDER, { root: true });
  },

  reset({ commit }) {
    commit('reset');
  },

  refresh({ commit }, levels) {
    commit('refresh', levels);
  }
};

const mutations = {
  reset(state) {
    Object.assign(state, emptyState);
  },

  refresh(state, payload) {
    state.levels = [...payload];
    if (state.editedLevel) {
      state.editedLevel = payload.find((level) => level.id === state.editedLevel.id);
    } else {
      state.editedLevel = payload.find((level) => level.order === 0);
    }
  },

  loadFromHistory(state, payload) {
    state.levels = payload;
    if (payload.filter((level) => level.id === state.editedLevel.id).length === 0) {
      state.editedLevel = state.levels[state.levels.length - 1];
    }
  },

  export() {},

  addNew(state, newId) {
    const lastOrder = state.levels[0].order;
    state.levels.unshift({
      id: newId,
      name: 'new level',
      isStreetLevel: false,
      order: lastOrder + 1
    });
    state.numberOfNewLevels++;
  },

  delete(state, levelId) {
    state.levels = state.levels.filter((level) => level.id !== levelId);
  },

  change(state, levelId) {
    state.editedLevel = state.levels.find((level) => level.id === levelId);
  },

  duplicate(state, { levelIndex, newLevel }) {
    state.levels.splice(levelIndex, 0, newLevel);
    state.numberOfNewLevels++;
  },

  order(state, levels) {
    const streetLevelIx = levels.findIndex((level) => level.isStreetLevel);
    // levelIx + levelOrder is constant and equal to streetLevelIx, because the levels are reversed
    // set the order of every level to the value, so that
    // levelIx + levelOrder = streetLevelIx (where order = 0)
    for (let i = 0; i < levels.length; i++) {
      levels[i].order = streetLevelIx - i;
    }
    state.levels = levels;
  },

  setStreetLevel(state, levelId) {
    state.levels.map((level) => (level.isStreetLevel = false));
    const idx = state.levels.findIndex((level) => level.id === levelId);
    state.levels[idx].isStreetLevel = true;
  }
};

const getters = {
  levels: (state) => state.levels,
  editedLevel: (state) => state.editedLevel,
  editedLevelId: (state) => state.editedLevel?.id,
  editedLevelOrder: (state) => state.editedLevel?.order,
  editedLevelName: (state) => state.editedLevel?.name,
  numberOfNewLevels: (state) => state.numberOfNewLevels
};

export const level = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
