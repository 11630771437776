import Api from './AxiosInstance';

export default {
  login(username, password) {
    return Api.post('api/auth/login', { username, password }, { withCredentials: true });
  },

  logout() {
    return Api.post('api/auth/logout', {}, { withCredentials: true });
  },

  refreshToken() {
    return Api.post('api/auth/refresh', {}, { withCredentials: true });
  }
};
