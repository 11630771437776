import axios from 'axios';
import config from '../helpers/configProvider';
import { store } from '../store/index';

export let localStorageKey = 'token';
const basePath = config.getEnv(config.enum.VUE_APP_BASE_PATH);
if (basePath) {
  localStorageKey = basePath.replace('/', '') + '_token';
}

const axiosInstance = axios.create({
  baseURL: config.getBaseApiServiceUrl(),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.request.use((config) => {
  if (!store.state.account.authDisabled) {
    const token = localStorage.getItem(localStorageKey);

    if (token) {
      config.headers.Authorization = 'Bearer ' + token;
    }
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (config) => config,
  function(error) {
    if (401 === error.response?.status) {
      store.dispatch('account/logout');
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
