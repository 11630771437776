<!-- note that maxlength is not a Vuetify but an HTML attribute -->
<template>
  <v-text-field
    :counter="showCounter"
    v-bind="{ maxlength: maxLength, value, ...$attrs }"
    v-on="$listeners"
  >
    <template>
      <slot name="default" />
    </template>

    <!-- Pass on all named slots -->
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />

    <!-- Pass on all scoped slots -->
    <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope"
      ><slot :name="slot" v-bind="scope"
    /></template>
  </v-text-field>
</template>

<script>
export default {
  name: 'GliTextField',
  props: { maxLength: { type: Number, default: 100 }, value: String },

  computed: {
    showCounter() {
      return !!(this.value && this.value.length && this.value.length > this.maxLength - 10);
    }
  }
};
</script>
