import Vue from 'vue';
import constants from '~/shared/constants';
import { getNewUniqueId } from '~/shared/utils';
import confirm from '../helpers/confirm';
import i18n from '../i18n';

const emptyState = {
  imagesByLevel: {}, // images in an object, keys are levelIds, each level has an array
  imagesOnLevel: [], // images on current level
  selectedImageUrl: undefined,
  selectedImageMeta: undefined,
  loadedFromHistory: false // used to prevent flickering when updating the source
};

const state = {
  ...emptyState
};

const actions = {
  async uploadImage({ commit, dispatch }, { image, meta }) {
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.onerror = (error) => reject(error);
      });
    let base64 = await toBase64(image);
    commit('addImage', { base64, meta });
    commit('selectImage', meta.id);
    dispatch('history/add', constants.OPERATIONS.IMAGE.UPLOAD, { root: true });
  },

  async setImageMeta({ commit, dispatch }, payload) {
    commit('setMeta', payload);
    if (!payload.skipHistory) {
      dispatch('history/add', constants.OPERATIONS.IMAGE.MODIFY, { root: true });
    }
  },

  async deleteImage({ commit, dispatch, rootGetters }) {
    const answer = await confirm(i18n.t('Are you sure you want to delete this custom image?'), {
      title: i18n.t('Delete custom image'),
      buttonTrueText: i18n.t('Delete'),
      buttonFalseText: i18n.t('Cancel')
    });

    if (answer) {
      commit('deleteImage', rootGetters['level/editedLevelId']);
      commit('deselectImage');
      dispatch('history/add', constants.OPERATIONS.IMAGE.DELETE, { root: true });
    }
  },

  refresh({ commit, rootGetters }, payload) {
    commit('refresh', { images: payload, currentLevelId: rootGetters['level/editedLevelId'] });
  },

  loadFromHistory({ commit, rootGetters }, payload) {
    commit('loadFromHistory', {
      images: payload,
      currentLevelId: rootGetters['level/editedLevelId']
    });
    commit('deselectImage');
    commit('loadedFromHistory', true);
  },

  selectImage({ commit }, payload) {
    commit('selectImage', payload.meta.id);
  },

  deselectImage({ commit }) {
    commit('deselectImage');
  },

  duplicate({ commit, getters }, newCoords) {
    const newId = getNewUniqueId();
    const newImageMeta = {
      ...getters.selectedImageMeta,
      id: newId,
      coordinates: newCoords
    };
    const newImageBase64 = getters.imagesOnLevel.find(
      (img) => img.meta.id === getters.selectedImageMeta.id
    ).base64;
    commit('addImage', { base64: newImageBase64, meta: newImageMeta });
    commit('selectImage', newId);
  },

  reset({ commit }) {
    commit('reset');
  },

  clearLoadedFromHistory({ commit }) {
    commit('loadedFromHistory', false);
  }
};

const mutations = {
  reset(state) {
    Object.assign(state, emptyState);
  },

  refresh(state, payload) {
    state.imagesByLevel = {};
    state.imagesOnLevel = [];
    const currentLevelId = payload.currentLevelId;
    delete payload.currentLevelId;
    const images = [...payload.images];
    for (const image of images) {
      const imageObject = {
        base64: image.buffer,
        meta: {
          levelId: image._levelId,
          size: image.size,
          coordinates: image.coordinates,
          id: image._id,
          mimeType: image.mimeType,
          name: image.name
        }
      };
      if (state.imagesByLevel && state.imagesByLevel[image._levelId]) {
        state.imagesByLevel[image._levelId].push(imageObject);
      } else if (state.imagesByLevel) {
        Vue.set(state.imagesByLevel, image._levelId, [imageObject]);
      }
      if (image._levelId === currentLevelId) {
        state.imagesOnLevel.push(imageObject);
      }
    }
  },

  loadFromHistory(state, payload) {
    state.imagesByLevel = {};
    state.imagesOnLevel = [];
    const currentLevelId = payload.currentLevelId;
    delete payload.currentLevelId;
    for (const levelId in payload.images) {
      const images = [...payload.images[levelId]];
      for (const image of images) {
        const imageObject = {
          base64: image.base64,
          meta: image.meta
        };
        if (state.imagesByLevel && state.imagesByLevel[image.meta.levelId]) {
          state.imagesByLevel[image.meta.levelId].push(imageObject);
        } else if (state.imagesByLevel) {
          Vue.set(state.imagesByLevel, image.meta.levelId, [imageObject]);
        }
        if (image.meta.levelId === currentLevelId) {
          state.imagesOnLevel.push(imageObject);
        }
      }
    }
  },

  addImage(state, { base64, meta }) {
    if (state.imagesByLevel && state.imagesByLevel[meta.levelId]) {
      state.imagesByLevel[meta.levelId].push({ base64, meta });
    } else if (state.imagesByLevel) {
      Vue.set(state.imagesByLevel, meta.levelId, [{ base64, meta }]);
    }
    state.imagesOnLevel.push({ base64, meta });
  },

  changeLevel(state, levelId) {
    if (state.imagesByLevel[levelId]) {
      state.imagesOnLevel = [...state.imagesByLevel[levelId]];
    } else {
      state.imagesOnLevel = [];
    }
    state.selectedImageUrl = undefined;
    state.selectedImageMeta = undefined;
  },

  setMeta(state, meta) {
    const ix = state.imagesByLevel[meta.levelId].findIndex((img) => img.meta.id === meta.id);
    Vue.set(state.imagesByLevel[meta.levelId][ix], 'meta', meta);
    state.imagesOnLevel[ix].meta = meta;
    state.selectedImageMeta = meta;
  },

  selectImage(state, id) {
    const img = state.imagesOnLevel.find((i) => i.meta.id === id);
    if (img) {
      state.selectedImageUrl = `data:${img.meta.mimeType}${img.meta.levelId};base64,${img.base64}`;
      state.selectedImageMeta = img.meta;
    }
  },

  deselectImage(state) {
    state.selectedImageUrl = undefined;
    state.selectedImageMeta = undefined;
  },

  duplicateLevel(state, { oldLevelId, newLevelId }) {
    if (state.imagesByLevel[oldLevelId]) {
      const images = [];
      for (const image of state.imagesByLevel[oldLevelId]) {
        images.push({
          ...image,
          meta: { ...image.meta, id: getNewUniqueId(), levelId: newLevelId }
        });
      }
      Vue.set(state.imagesByLevel, newLevelId, images);
    }
  },

  deleteImage(state, currentLevelId) {
    const imageId = state.selectedImageMeta.id;
    state.imagesOnLevel = state.imagesOnLevel.filter((img) => img.meta.id !== imageId);
    state.imagesByLevel[currentLevelId] = state.imagesByLevel[currentLevelId].filter(
      (img) => img.meta.id !== imageId
    );
  },

  deleteLevel(state, levelId) {
    Vue.delete(state.imagesByLevel, levelId);
    state.imagesOnLevel = [];
  },

  loadedFromHistory(state, value) {
    state.loadedFromHistory = value;
  }
};

const getters = {
  selectedImageMeta: (state) => state.selectedImageMeta,
  selectedImageUrl: (state) => state.selectedImageUrl,
  imagesByLevel: (state) => state.imagesByLevel,
  imagesOnLevel: (state) => state.imagesOnLevel,
  loadedFromHistory: (state) => state.loadedFromHistory
};

export const customImage = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
