import Vue from 'vue';
import VueCookies from 'vue-cookies';
import vuetify from './plugins/vuetify';
import VueSweetalert2 from 'vue-sweetalert2';
// Ensure you are using css-loader
import 'vuetify/dist/vuetify.min.css';
import { store } from './store';
import { router } from './router';
import App from './App.vue';
import './filters';
import i18n from './i18n';
import config from './helpers/configProvider';

Vue.use(VueSweetalert2);
Vue.use(VueCookies);

// set default config: restrict to the base path
(VueCookies as any).config(
  '1d',
  (config.getEnv(config.enum.VUE_APP_BASE_PATH) || '/').replace(/\/+$/, '')
);

Vue.config.productionTip = false;

// register base components globally
const requireComponent = require.context('./components/base', false, /\.(vue|js)$/);
requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);

  const componentName = fileName
    .split('/')
    .pop()!
    .replace(/\.\w+$/, '');

  Vue.component(componentName, componentConfig.default || componentConfig);
});

// load client config before initiating Vue
store.dispatch('app/fetchConfig').then(() => {
  store.dispatch('account/refreshToken').then(() => {
    new Vue({
      vuetify,
      router,
      store,
      i18n,
      created() {
        if (process.env.NODE_ENV !== 'development') {
          window.addEventListener('beforeunload', this.beforeUnload);
        }
      },
      methods: {
        beforeUnload(e: any) {
          // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
          if (this.$store.state.history.history.length > 1) {
            e.preventDefault();
            e.returnValue = '';
          }
        }
      },
      render: (h) => h(App) // eslint-disable-line id-length
    }).$mount('#app');
  });
});
