<template>
  <v-dialog
    v-if="editedObject"
    :max-width="width ? width : 800"
    :value="editedObject"
    @click:outside="() => (isDirty ? null : $emit('cancel'))"
    @keydown.esc="() => (isDirty ? null : $emit('cancel'))"
    :persistent="isDirty"
  >
    <v-card>
      <v-card-title class="primary">
        {{ title }}
      </v-card-title>
      <v-card-text id="content-wrapper" class="pa-4">
        <v-form ref="form" @submit.prevent="save">
          <template>
            <slot name="default" />
          </template>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <slot name="actions">
          <v-btn
            v-if="!isNew && !deleteDisabled"
            class="mr-4"
            color="error"
            @click="$emit('delete')"
          >
            {{ deleteText }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="$emit('cancel')"> {{ cancelText }}</v-btn>
          <v-btn
            color="primary"
            :disabled="!isDirty || (oneSelected ? oneSelected : false)"
            @click="save"
          >
            {{ saveText }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
#content-wrapper {
  overflow-y: auto;
  max-height: calc(100vh - 200px);
}

.v-dialog > .v-card > .v-card__title {
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}

.v-card__actions button.v-btn {
  min-width: 75px;
}
</style>

<script>
export default {
  name: 'GliDialog',
  props: [
    'title',
    'buttonDeleteText',
    'buttonSaveText',
    'buttonCancelText',
    'editedObject',
    'isNew',
    'width',
    'oneSelected',
    'deleteDisabled'
  ],

  data() {
    return {
      isDirty: false,
      deleteText: this.buttonDeleteText || this.$t('Delete'),
      saveText: this.buttonSaveText || this.$t('Save'),
      cancelText: this.buttonCancelText || this.$t('Cancel')
    };
  },

  watch: {
    editedObject: {
      handler() {
        this.isDirty = true;
      },
      deep: true
    }
  },

  methods: {
    save() {
      if (this.$refs.form.validate()) {
        this.$emit('save');
      }
    }
  }
};
</script>
