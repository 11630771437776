<template>
  <div class="d-flex flex-grow-1 subtitle-2">
    <div v-if="WGS84" class="d-none d-md-block">{{ $t('Coordinates') }}:</div>
    <div v-if="WGS84" class="coords ml-2 d-none d-md-block">
      <div>EOV: {{ `[${EOV[0].toFixed(4)}, ${EOV[1].toFixed(4)}] ` }}</div>
      <div>WGS84: {{ `[${WGS84[0].toFixed(8)}, ${WGS84[1].toFixed(8)}] ` }}</div>
    </div>
    <div v-if="snappingWGS84" class="ml-2 d-none d-lg-block">{{ $t('Snapping to') }}:</div>
    <div v-if="snappingWGS84" class="coords ml-2 d-none d-lg-block">
      <div>EOV: {{ `[${snappingEOV[0].toFixed(4)}, ${snappingEOV[1].toFixed(4)}] ` }}</div>
      <div>WGS84: {{ `[${snappingWGS84[0].toFixed(8)}, ${snappingWGS84[1].toFixed(8)}] ` }}</div>
    </div>

    <div class="ml-2 d-none d-sm-block" v-if="edgeProps">
      {{ $t('Edge length') }}: {{ (edgeProps.length * 1000).toFixed(2) }} m
      <span v-if="edgeProps.minAngle" class="ml-2"
        >{{ $t('Smallest angle') }}: {{ edgeProps.minAngle.toFixed(2) }}°</span
      >
    </div>
    <v-spacer />
    <span v-if="version">{{ $t('Application version') }}: {{ version.buildVersion }}</span>
  </div>
</template>

<style lang="scss" scoped>
.coords {
  margin-top: -2px;
  font-size: 0.8em !important;
  line-height: 1.1em;
  text-align: right;
}
</style>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
export default {
  name: 'StatusBar',

  data() {
    return {};
  },
  computed: {
    ...mapGetters('app', ['version']),
    ...mapGetters('status', ['WGS84', 'EOV', 'snappingWGS84', 'snappingEOV']),
    ...mapState({ edgeProps: (state) => state.status.edgeProps })
  },

  async mounted() {
    await this.fetchVersion();
  },

  methods: {
    ...mapActions('app', ['fetchVersion'])
  },
  components: {}
};
</script>
