import Api from './AxiosInstance';
import User from './User';
import Organization from './Organization';
import Site from './Site';
import Auth from './Auth';
import Venue from './Venue';

export default {
  ...Site,
  ...Auth,
  ...User,
  ...Organization,
  ...Venue,

  getVersion() {
    return Api.get('/api/version');
  },

  getConfig() {
    return Api.get('/api/config');
  },

  getBaseMapStyle(baseMapStyle: string) {
    return Api.get('/api/style', { params: { baseMapStyle } });
  },

  imageConvert(imageFile: File) {
    const formData = new FormData();
    formData.append('image', imageFile);
    return Api.post('/api/image/convert', formData, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },

  coordinateConvert(projection: number, coords: number[]) {
    const query = new URLSearchParams();
    if (projection === 23700) {
      query.append('eov', `${coords[0]},${coords[1]}`);
    }
    return Api.get('/api/coordinate/converter', {
      params: query
    });
  },

  calculateRoute(siteId: string, formData: any) {
    const url = `/api/sites/${siteId}/route`;
    return Api.post(url, formData);
  }
};
