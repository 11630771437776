import { ZoneProperties, Zone, ZoneFeature } from '@/types/types';
import { ActionTree } from 'vuex';
import constants from '~/shared/constants';
import { ZoneActions } from './actions';
import { ZoneGetters } from './getters';

const Mutations = {
  EDIT: 'edit',
  CLEAR_STATE: 'clearState',
  SET_PROPERTIES: 'setProperties'
};

const emptyZone: ZoneProperties = {
  id: undefined,
  levelId: undefined,
  color: constants.STYLES.ZONE.DEFAULT_COLOR,
  opacity: constants.STYLES.ZONE.DEFAULT_FILL_OPACITY,
  displayName: undefined,
  persistentId: undefined
};

type ZoneState = {
  editedZone?: ZoneProperties;
};

const state: ZoneState = {
  editedZone: undefined
};

const actions: ActionTree<ZoneState, any> = {
  [ZoneActions.EDIT]({ commit }, zone: ZoneFeature) {
    commit(Mutations.EDIT, { ...zone.properties, id: zone.id });
  },

  [ZoneActions.SET_PROPERTIES]({ commit, dispatch }, properties: Partial<ZoneProperties>) {
    let shouldGenerateId = false;
    if (
      !state.editedZone!.persistentId &&
      !state.editedZone!.displayName &&
      properties.displayName
    ) {
      shouldGenerateId = true;
    }
    commit(Mutations.SET_PROPERTIES, properties);
    if (shouldGenerateId) {
      dispatch(ZoneActions.GENERATE_ID);
    }
  },

  [ZoneActions.GENERATE_ID]({ commit, rootGetters }) {
    let id = '';
    id += rootGetters['level/editedLevelName'];
    id += '-';
    id +=
      state.editedZone!.displayName &&
      state.editedZone!.displayName.length > 0 &&
      state.editedZone!.displayName.split(/[-_ ]+/).length > 0
        ? state.editedZone!.displayName.split(/[-_ ]+/)[0]
        : 'zone';
    const allZoneIds = rootGetters['feature/allFeatures']
      .filter(
        (feature: any) =>
          feature.feature.properties._type === constants.FEATURE_TYPES.ZONE &&
          feature.id !== state.editedZone!.id
      )
      .map((zone: Zone) => zone.feature.properties.persistentId);
    if (allZoneIds.includes(id)) {
      let ix = 0;
      while (allZoneIds.includes(id + '-' + ix)) {
        ix++;
      }
      id += '-' + ix;
    }
    commit(Mutations.SET_PROPERTIES, { persistentId: id });
  },

  [ZoneActions.SAVE]({ dispatch }) {
    dispatch('feature/editFeatureProperties', state.editedZone, { root: true });
    const operation = constants.OPERATIONS.ZONE.MODIFY;
    dispatch('history/add', operation, { root: true });
  },

  [ZoneActions.CLEAR_STATE]({ commit }) {
    commit(Mutations.CLEAR_STATE);
  }
};

const mutations = {
  [Mutations.EDIT](state: ZoneState, zone: ZoneProperties) {
    state.editedZone = { ...emptyZone, id: zone.id, levelId: zone.levelId };
    state.editedZone.color = zone.color ?? state.editedZone.color;
    state.editedZone.opacity = zone.opacity ?? state.editedZone.opacity;
    state.editedZone.displayName = zone.displayName ?? state.editedZone.displayName;
    state.editedZone.persistentId = zone.persistentId ?? state.editedZone.persistentId;
  },

  [Mutations.SET_PROPERTIES](state: ZoneState, properties: Partial<ZoneProperties>) {
    state.editedZone!.color = properties.color ?? state.editedZone!.color;
    state.editedZone!.opacity = properties.opacity ?? state.editedZone!.opacity;
    state.editedZone!.displayName = properties.displayName ?? state.editedZone!.displayName;
    state.editedZone!.persistentId = properties.persistentId ?? state.editedZone!.persistentId;
  },

  [Mutations.CLEAR_STATE](state: ZoneState) {
    state.editedZone = undefined;
  }
};

const getters = {
  [ZoneGetters.GET_COLOR_DATA]: (state: ZoneState) => {
    return {
      color: state.editedZone?.color,
      opacity: state.editedZone?.opacity
    };
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
