import Vue from 'vue';
import Router from 'vue-router';
import config from '../helpers/configProvider';
import { store } from '../store';
import { localStorageKey } from '../services/AxiosInstance';
import i18n from '../i18n';

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  base: config.getEnv(config.enum.VUE_APP_BASE_PATH) || '/',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/FloorplanEditor')
    },
    {
      path: '/draw/:siteId',
      name: 'Draw',
      component: () => import('@/views/FloorplanEditor')
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/Login')
    },
    {
      path: '/users',
      name: 'Users',
      component: () => import('@/views/Users')
    },
    {
      path: '/organizations',
      name: 'Organizations',
      component: () => import('@/views/Organizations')
    },
    {
      path: '/venues',
      name: 'Venues',
      component: () => import('@/views/Venues')
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  store.dispatch('alert/clear');

  if (process.env.NODE_ENV !== 'development') {
    if (store.state.history.history.length > 1 || store.state.stairs.addStartedOnLevelId) {
      if (
        await router.app.$confirm(
          i18n.t(
            'There are unsaved changes, that will be lost. Do you really want to leave this page?'
          ),
          {
            title: i18n.t('Warning'),
            width: 500,
            buttonTrueText: i18n.t('Leave page'),
            buttonFalseText: i18n.t('Cancel')
          }
        )
      ) {
        store.dispatch('history/clear');
        store.dispatch('stairs/reset');
        next();
      } else {
        return next(false);
      }
    }
  }

  if (store.state.account.authDisabled) {
    // redirect to root if auth is disabled and trying to access auth related pages
    const authPages = ['/users', '/login'];
    if (authPages.includes(to.path.toLowerCase())) {
      return next('/');
    }

    next();
  } else {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path.toLowerCase());
    const loggedIn = localStorage.getItem(localStorageKey);

    if (authRequired && !loggedIn) {
      return next('/Login');
    }

    if (to.path.toLowerCase() === '/organizations' && !store.getters['account/isSuperAdmin']) {
      return next('/');
    }
    if (to.path.toLowerCase() === '/users' && !store.getters['account/isAdmin']) {
      return next('/');
    }
    if (
      to.path.toLowerCase() === '/venues' &&
      !store.getters['account/isAdmin'] &&
      !store.getters['account/isEditor'] &&
      !store.getters['account/isOperator']
    ) {
      return next('/');
    }

    next();
  }
});
