import { getField, updateField } from 'vuex-map-fields';
import { distance as turfDistance, nearestPointOnLine as turfNearestPointOnLine } from '@turf/turf';
import confirm from '../helpers/confirm';
import constants from '~/shared/constants';
import i18n from '../i18n';
import { getNewUniqueId, splitLine } from '~/shared/utils';

// these fields need to exist to become reactive
const emptyElevator = {
  id: null,
  levelId: null,
  feature: {
    type: 'Feature',
    geometry: {
      coordinates: null,
      type: 'Point'
    },
    properties: {
      _type: constants.FEATURE_TYPES.JUNCTION,
      type: constants.JUNCTION_TYPES.ELEVATOR,
      levelId: null
    }
  }
};

const state = {
  editedElevator: null,
  startingLevelId: null,
  startingJunctionId: null,
  chosenLevels: [],
  isNew: false,
  moveType: constants.ELEVATOR_MOVE_TYPES.FREE,
  lastMoveWasSuccessful: true
};

/** Returns copies of all the exchange points and junctions of which the elevator consists  */
const getElevatorData = (elevatorId, exchangePoints, featuresByLevel) => {
  let exchPointsOfElevator = new Set();
  let junctionIds = [];
  let elevatorStops = [elevatorId];
  while (elevatorStops.length > 0) {
    const stop = elevatorStops.pop();
    junctionIds.push(stop);
    const filteredEPs = exchangePoints.filter(
      (exchangePoint) => exchangePoint.fromJunction === stop || exchangePoint.toJunction === stop
    );

    for (const ep of filteredEPs) {
      exchPointsOfElevator.add(ep);
      if (!junctionIds.includes(ep.fromJunction)) {
        elevatorStops.push(ep.fromJunction);
      }
      if (!junctionIds.includes(ep.toJunction)) {
        elevatorStops.push(ep.toJunction);
      }
    }
  }

  let junctions = [];
  for (const levelId in featuresByLevel) {
    junctions = junctions.concat(
      featuresByLevel[levelId].filter((feature) => junctionIds.includes(feature.id))
    );
  }

  exchPointsOfElevator = Array.from(exchPointsOfElevator);

  return {
    exchangePoints: JSON.parse(JSON.stringify(exchPointsOfElevator)),
    junctions: JSON.parse(JSON.stringify(junctions))
  };
};

/** Snap the nearest junction or segment to the elevator on the specified level */
const snapToElevator = ({ commit, levelFeatures, elevator, junctionOnLevel }) => {
  let alreadySnapped = false;

  const nearestJunction = levelFeatures.reduce((nearest, current) => {
    if (
      current.feature.properties._type === constants.FEATURE_TYPES.JUNCTION &&
      current.feature.properties.type === constants.JUNCTION_TYPES.NORMAL
    ) {
      const distance = turfDistance(elevator.coordinates, current.feature.geometry.coordinates);

      if (!nearest || nearest.distance > distance) {
        return { distance, junction: current };
      }
    }
    return nearest;
  }, undefined);

  if (nearestJunction && nearestJunction.distance <= constants.ELEVATOR_SNAP_DISTANCE) {
    const connectedSegmentsToNearestJunction = levelFeatures.filter(
      ({ feature }) =>
        feature.properties.fromJunction === nearestJunction.junction.id ||
        feature.properties.toJunction === nearestJunction.junction.id
    );

    if (connectedSegmentsToNearestJunction.length) {
      for (let connectedSegment of connectedSegmentsToNearestJunction) {
        if (connectedSegment.feature.properties.fromJunction === nearestJunction.junction.id) {
          connectedSegment.feature.geometry.coordinates[0] = elevator.coordinates;
          connectedSegment.feature.properties.fromJunction = junctionOnLevel.id;
        }
        if (connectedSegment.feature.properties.toJunction === nearestJunction.junction.id) {
          connectedSegment.feature.geometry.coordinates[1] = elevator.coordinates;
          connectedSegment.feature.properties.toJunction = junctionOnLevel.id;
        }
        commit('feature/editFeature', connectedSegment, { root: true });
      }
    }

    commit('feature/deleteFeature', nearestJunction.junction.id, { root: true });

    alreadySnapped = true;
  }

  if (!alreadySnapped) {
    const nearestSegment = levelFeatures.reduce((nearest, current) => {
      if (
        current.feature.properties._type === constants.FEATURE_TYPES.NETWORK_SEGMENT &&
        current.feature.properties.fromJunction !== junctionOnLevel.id &&
        current.feature.properties.toJunction !== junctionOnLevel.id
      ) {
        const nearestPointOnLine = turfNearestPointOnLine(current.feature, elevator.coordinates);

        const distance = turfDistance(
          elevator.coordinates,
          nearestPointOnLine.geometry.coordinates
        );

        if (!nearest || nearest.distance > distance) {
          return { distance, segment: current };
        }
      }
      return nearest;
    }, undefined);

    if (nearestSegment && nearestSegment.distance <= constants.ELEVATOR_SNAP_DISTANCE) {
      let [lineA, lineB] = splitLine(nearestSegment.segment.feature, {
        ...junctionOnLevel.feature,
        id: junctionOnLevel.id
      });

      lineA.id = getNewUniqueId();
      lineB.id = getNewUniqueId();

      commit(
        'feature/addFeature',
        { id: lineA.id, levelId: junctionOnLevel.feature.properties.levelId, feature: lineA },
        { root: true }
      );
      commit(
        'feature/addFeature',
        { id: lineB.id, levelId: junctionOnLevel.feature.properties.levelId, feature: lineB },
        { root: true }
      );

      commit('feature/deleteFeature', nearestSegment.segment.id, { root: true });
      alreadySnapped = true;
    }
  }

  if (alreadySnapped) {
    // check and remove multiple connections (eg. a triangle became a line)
    const connectedSegments = levelFeatures.filter(
      ({ feature }) =>
        feature.properties._type === constants.FEATURE_TYPES.NETWORK_SEGMENT &&
        (feature.properties.fromJunction === junctionOnLevel.id ||
          feature.properties.toJunction === junctionOnLevel.id)
    );

    while (connectedSegments.length) {
      const segmentA = connectedSegments.shift();
      if (
        connectedSegments.some(
          (segmentB) =>
            (segmentA.feature.properties.fromJunction ===
              segmentB.feature.properties.fromJunction &&
              segmentA.feature.properties.toJunction === segmentB.feature.properties.toJunction) ||
            (segmentA.feature.properties.fromJunction === segmentB.feature.properties.toJunction &&
              segmentA.feature.properties.toJunction === segmentB.feature.properties.fromJunction)
        )
      ) {
        commit('feature/deleteFeature', segmentA.id, { root: true });
      }
    }
  }
};

const actions = {
  startAdding({ commit }, payload) {
    commit('addElevator', payload);
  },

  move({ dispatch, commit, rootGetters, state }, payload) {
    const elevators = payload;

    const allExchangePoints = rootGetters['feature/exchangePoints'];
    const featuresByLevel = rootGetters['feature/featuresByLevel'];

    let pathMovedOnLevels = {};

    for (let elevator of elevators) {
      const { junctions } = getElevatorData(elevator.id, allExchangePoints, featuresByLevel);
      const currentJunction = junctions.find((junction) => junction.id === elevator.id);

      for (let junctionOnLevel of junctions) {
        if (junctionOnLevel.id !== currentJunction.id) {
          const levelFeatures = featuresByLevel[junctionOnLevel.feature.properties.levelId];
          const connectedSegments = levelFeatures.filter(
            ({ feature }) =>
              feature.properties.fromJunction === junctionOnLevel.id ||
              feature.properties.toJunction === junctionOnLevel.id
          );

          // in free move mode remove the moved elevator junction from the path
          // and add and connect a new normal node to the path in the same position
          if (state.moveType === constants.ELEVATOR_MOVE_TYPES.FREE) {
            if (connectedSegments.length) {
              let normalJunction = JSON.parse(JSON.stringify(junctionOnLevel));
              normalJunction.id = getNewUniqueId();
              normalJunction.levelId = normalJunction.feature.properties.levelId;
              normalJunction.feature.properties.type = constants.JUNCTION_TYPES.NORMAL;
              commit('feature/addFeature', normalJunction, { root: true });

              for (let connectedSegment of connectedSegments) {
                if (connectedSegment.feature.properties.fromJunction === junctionOnLevel.id) {
                  connectedSegment.feature.properties.fromJunction = normalJunction.id;
                }
                if (connectedSegment.feature.properties.toJunction === junctionOnLevel.id) {
                  connectedSegment.feature.properties.toJunction = normalJunction.id;
                }
                commit('feature/editFeature', connectedSegment, { root: true });
              }
            }
          } else if (state.moveType === constants.ELEVATOR_MOVE_TYPES.WITH_PATH) {
            if (connectedSegments.length) {
              pathMovedOnLevels[junctionOnLevel.feature.properties.levelId] = true;

              for (let connectedSegment of connectedSegments) {
                if (connectedSegment.feature.properties.fromJunction === junctionOnLevel.id) {
                  connectedSegment.feature.geometry.coordinates[0] = elevator.coordinates;
                }
                if (connectedSegment.feature.properties.toJunction === junctionOnLevel.id) {
                  connectedSegment.feature.geometry.coordinates[1] = elevator.coordinates;
                }
                commit('feature/editFeature', connectedSegment, { root: true });
              }
            }
          }

          // update the elevator junction coordinate on the given level
          // with the moved junction coordinates
          junctionOnLevel.feature.geometry.coordinates = elevator.coordinates;
          commit('feature/editFeature', junctionOnLevel, { root: true });

          snapToElevator({
            commit,
            levelFeatures:
              rootGetters['feature/featuresByLevel'][junctionOnLevel.feature.properties.levelId],
            elevator,
            junctionOnLevel
          });

          const levels = rootGetters['level/levels'];

          // check special junction distance
          const specialJunctionsOnLevel = rootGetters['feature/featuresByLevel'][
            junctionOnLevel.feature.properties.levelId
          ].filter(
            (feature) =>
              feature.id !== junctionOnLevel.id &&
              feature.feature.properties._type === constants.FEATURE_TYPES.JUNCTION &&
              feature.feature.properties.type !== constants.JUNCTION_TYPES.NORMAL
          );
          for (let specialJunction of specialJunctionsOnLevel) {
            const distance = turfDistance(
              elevator.coordinates,
              specialJunction.feature.geometry.coordinates
            );
            if (distance < constants.ELEVATOR_SNAP_DISTANCE) {
              dispatch(
                'alert/error',
                i18n.t('The elevator move was unsuccessful.') +
                  ' ' +
                  i18n.t('Special junctions cannot be overwritten on the following level:') +
                  ' ' +
                  levels.find((level) => level.id === junctionOnLevel.feature.properties.levelId)
                    .name +
                  '.',
                {
                  root: true
                }
              );
              dispatch('history/loadCurrent', null, { root: true });
              commit('setMoveResult', false);
              return;
            }
          }
        }
      }
    }

    const levels = rootGetters['level/levels'];
    let pathMovedOnLevelsString = Object.keys(pathMovedOnLevels)
      .map((levelId) => {
        return levels.find((level) => level.id === levelId).name;
      })
      .join(', ');

    dispatch(
      pathMovedOnLevelsString ? 'alert/info' : 'alert/success',
      i18n.t('The elevator move was successful.') +
        (pathMovedOnLevelsString
          ? ' ' + i18n.t('The path also moved on level(s):') + ' ' + pathMovedOnLevelsString
          : ''),
      { root: true }
    );
  },

  edit({ commit, rootGetters }, elevatorId) {
    const allExchangePoints = rootGetters['feature/exchangePoints'];
    const featuresByLevel = rootGetters['feature/featuresByLevel'];

    const { exchangePoints, junctions } = getElevatorData(
      elevatorId,
      allExchangePoints,
      featuresByLevel
    );
    commit('editElevator', {
      currentStopId: elevatorId,
      exchangePoints,
      junctions
    });
  },

  finishAdding({ commit, state, dispatch, rootGetters }) {
    /** TODO Check if the newly added elevator junctions intersects
     * any existing junction or network segment. */

    let newElevator;
    let fromJunctionId = getNewUniqueId();
    let toJunctionId = getNewUniqueId();
    const initGeometry = state.editedElevator.feature.geometry;

    // add first elevator stop
    if (state.startingLevelId === state.chosenLevels[0].id) {
      fromJunctionId = state.startingJunctionId;
    } else {
      newElevator = JSON.parse(JSON.stringify(emptyElevator));
      newElevator.id = fromJunctionId;
      newElevator.levelId = state.chosenLevels[0].id;
      newElevator.feature.properties.levelId = state.chosenLevels[0].id;
      newElevator.feature.geometry = initGeometry;
      commit('feature/addFeature', newElevator, { root: true });
    }

    for (let levelIdx = 1; levelIdx < state.chosenLevels.length; levelIdx++) {
      const level = state.chosenLevels[levelIdx];

      // create new elevator junction
      if (state.startingLevelId === level.id) {
        toJunctionId = state.startingJunctionId;
      } else {
        let newConnectedElevator = JSON.parse(JSON.stringify(emptyElevator));
        newConnectedElevator.id = toJunctionId;
        newConnectedElevator.levelId = level.id;
        newConnectedElevator.feature.properties.levelId = level.id;
        newConnectedElevator.feature.geometry = initGeometry;
        commit('feature/addFeature', newConnectedElevator, { root: true });
      }
      // create its exchange point
      const newExchangePointId = getNewUniqueId();
      const newExchangePoint = {
        id: newExchangePointId,
        type: constants.EXCHANGE_POINT_TYPES.LEVEL_TRANSITION,
        method: constants.EXCHANGE_POINT_METHODS.ELEVATOR_SHAFT,
        _siteId: rootGetters['site/editedSite'].id,
        fromJunction: fromJunctionId,
        toJunction: toJunctionId
      };
      dispatch('feature/addExchangePoint', newExchangePoint, { root: true });

      // do not generate new ids if there is not any more levels
      if (level.id !== state.chosenLevels[state.chosenLevels.length - 1].id) {
        fromJunctionId = toJunctionId;
        toJunctionId = getNewUniqueId();
      }
    }

    dispatch('history/add', constants.OPERATIONS.NETWORK.ADD_ELEVATOR, { root: true });
    commit('reset');
    dispatch('alert/success', i18n.t('The elevator was created successfully.'), { root: true });
  },

  async modify(
    { commit, state, dispatch, rootGetters },
    { editedElevator, connectedLevelsIds, addToHistory = true }
  ) {
    /** TODO Check if the newly added elevator junctions intersects
     * any existing junction or network segment. */

    const edited = editedElevator ? editedElevator : state.editedElevator;
    let newlyChosenLevelIds;
    if (connectedLevelsIds) {
      newlyChosenLevelIds = connectedLevelsIds;
    } else {
      newlyChosenLevelIds = state.chosenLevels.map((level) => level.id);
    }

    const editedElevatorId = edited.id;

    const allExchangePoints = rootGetters['feature/exchangePoints'];
    const featuresByLevel = rootGetters['feature/featuresByLevel'];
    const { exchangePoints, junctions } = getElevatorData(
      editedElevatorId,
      allExchangePoints,
      featuresByLevel
    );

    let modifiedElevators = {};

    // #region handle old elevator connections
    // delete all old exchange points
    for (const exchPoint of exchangePoints) {
      commit('feature/deleteExchangePoint', exchPoint, { root: true });
    }

    //  check junctions on old connected levels:
    /* - if it is in the newly modified data too --> keep
     * - if it is not in the newly modified data, but connected to network --> to normal junction
     * - if it is not in the newly modified data and not connected --> delete */
    for (let oldElevator of junctions) {
      if (newlyChosenLevelIds.includes(oldElevator.feature.properties.levelId)) {
        // keep this junction at its original form and store it as a checked junction
        modifiedElevators[oldElevator.feature.properties.levelId] = oldElevator.id;
      } else {
        const netSegConnectedToElevator = await dispatch(
          'feature/networkSegmentIsConnected',
          {
            junctionId: oldElevator.id,
            levelId: oldElevator.feature.properties.levelId
          },
          { root: true }
        );

        if (netSegConnectedToElevator) {
          // modify to normal junction and update
          oldElevator.feature.properties.type = constants.JUNCTION_TYPES.NORMAL;
          commit('feature/editFeature', oldElevator, { root: true });
        } else {
          // delete this elevator junction
          commit('feature/deleteFeature', oldElevator.id, { root: true });
        }
      }
    }
    // #endregion

    // #region add newly added levels and connections
    for (const connectedLevelId of newlyChosenLevelIds) {
      const alreadyCheckedElevatorStops = Object.keys(modifiedElevators);
      if (!alreadyCheckedElevatorStops.includes(connectedLevelId)) {
        // add a new elevator to this level
        let newElevator = JSON.parse(JSON.stringify(emptyElevator));
        newElevator.id = getNewUniqueId();
        newElevator.levelId = connectedLevelId;
        newElevator.feature.properties.levelId = connectedLevelId;
        newElevator.feature.geometry = state.editedElevator.feature.geometry;
        commit('feature/addFeature', newElevator, { root: true });

        modifiedElevators[connectedLevelId] = newElevator.id;
      }
    }
    // #endregion

    // #region regenerate all exchange points
    let fromJunctionId = modifiedElevators[newlyChosenLevelIds[0]];
    for (let idx = 1; idx < newlyChosenLevelIds.length; idx++) {
      const newExchangePointId = getNewUniqueId();
      const toJunctionId = modifiedElevators[newlyChosenLevelIds[idx]];
      const newExchangePoint = {
        id: newExchangePointId,
        type: constants.EXCHANGE_POINT_TYPES.LEVEL_TRANSITION,
        method: constants.EXCHANGE_POINT_METHODS.ELEVATOR_SHAFT,
        _siteId: rootGetters['site/editedSite'].id,
        fromJunction: fromJunctionId,
        toJunction: toJunctionId
      };
      dispatch('feature/addExchangePoint', newExchangePoint, { root: true });

      fromJunctionId = toJunctionId;
    }
    // #endregion

    const editedLevelId = rootGetters['level/editedLevelId'];
    commit('feature/updateCurrentFeatures', editedLevelId, { root: true });
    if (addToHistory) {
      dispatch('history/add', constants.OPERATIONS.NETWORK.ADD_ELEVATOR, { root: true });
      commit('reset');
    }
  },

  async delete({ commit, dispatch, rootGetters }, { elevatorId, calledFromContextMenu = true }) {
    let answer = true;
    if (calledFromContextMenu) {
      answer = await confirm(i18n.t('Are you sure you want to delete this elevator?'), {
        title: i18n.t('Delete elevator'),
        buttonTrueText: i18n.t('Delete'),
        buttonFalseText: i18n.t('Cancel')
      });
    }

    if (answer) {
      const editedLevelId = rootGetters['level/editedLevelId'];

      const allExchangePoints = rootGetters['feature/exchangePoints'];
      const featuresByLevel = rootGetters['feature/featuresByLevel'];
      const { exchangePoints, junctions } = getElevatorData(
        elevatorId,
        allExchangePoints,
        featuresByLevel
      );

      // delete connected exchange points
      for (const exchPoint of exchangePoints) {
        commit('feature/deleteExchangePoint', exchPoint, { root: true });
      }

      // delete or modify to normal junction all elevator junctions
      let modifiedElevator;
      for (const junc of junctions) {
        let deletedElevator = junc;
        const netSegConnectedToElevator = await dispatch(
          'feature/networkSegmentIsConnected',
          {
            junctionId: deletedElevator.id,
            levelId: deletedElevator.feature.properties.levelId
          },
          { root: true }
        );

        if (netSegConnectedToElevator) {
          deletedElevator.feature.properties.type = constants.JUNCTION_TYPES.NORMAL;
          if (deletedElevator.feature.properties.levelId === editedLevelId) {
            modifiedElevator = deletedElevator;
          }

          commit('feature/editFeature', deletedElevator, { root: true });
        } else {
          commit('feature/deleteFeature', deletedElevator.id, { root: true });
        }
      }

      commit('feature/updateCurrentFeatures', editedLevelId, { root: true });
      if (calledFromContextMenu) {
        dispatch('history/add', constants.OPERATIONS.FLOORPLAN.DELETE, { root: true });
      }
      commit('reset');

      return modifiedElevator ? modifiedElevator : null;
    }
  },

  deleteLevel({ dispatch, rootGetters }, { editedElevator, deletedLevelId }) {
    const allExchangePoints = rootGetters['feature/exchangePoints'];
    const featuresByLevel = rootGetters['feature/featuresByLevel'];
    const { junctions } = getElevatorData(editedElevator.id, allExchangePoints, featuresByLevel);

    const connectedLevelsIds = junctions
      .map((junction) => junction.feature.properties.levelId)
      .filter((levelId) => levelId !== deletedLevelId);

    if (connectedLevelsIds.length <= 1) {
      // elevator with one stop is not allowed, delete whole elevator
      dispatch('delete', {
        elevatorId: editedElevator.id,
        calledFromContextMenu: false
      });
    } else {
      dispatch('modify', { editedElevator, connectedLevelsIds, addToHistory: false });
    }
  },

  cancel({ commit }, { idToDelete }) {
    if (state.isNew && idToDelete) {
      commit('feature/deleteFeature', idToDelete);
    }
    commit('reset');
  },

  async updateExchangePoints({ rootGetters, commit }, movedLevelId) {
    const features = rootGetters['feature/featuresByLevel'];
    const elevatorsOfLevel = features[movedLevelId].filter(
      (feature) => feature.feature.properties.type === constants.JUNCTION_TYPES.ELEVATOR
    );
    const levels = rootGetters['level/levels'];

    // go through all elevators on this floor
    for (let elevator of elevatorsOfLevel) {
      const allExchangePoints = rootGetters['feature/exchangePoints'];
      const featuresByLevel = rootGetters['feature/featuresByLevel'];

      let { exchangePoints, junctions } = getElevatorData(
        elevator.id,
        allExchangePoints,
        featuresByLevel
      );
      junctions.sort((junctionA, junctionB) => {
        const idxJunctionA = levels.findIndex((level) => level.id === junctionA.levelId);
        const idxJunctionB = levels.findIndex((level) => level.id === junctionB.levelId);
        if (idxJunctionA > idxJunctionB) {
          return 1;
        }
        if (idxJunctionA < idxJunctionB) {
          return -1;
        }
        return 0;
      });
      for (let i = 0; i < junctions.length - 1; i++) {
        exchangePoints[i].fromJunction = junctions[i].id;
        exchangePoints[i].toJunction = junctions[i + 1].id;
        commit('feature/editExchangePoint', exchangePoints[i], { root: true });
      }
    }
  }
};

const mutations = {
  setMoveResult(state, payload) {
    state.lastMoveWasSuccessful = payload;
  },
  changeMoveType(state, payload) {
    state.moveType = payload;
  },
  addElevator(state, payload) {
    state.editedElevator = {
      ...emptyElevator
    };
    state.editedElevator.feature.geometry = {
      type: 'Point',
      coordinates: [...payload.feature.geometry.coordinates]
    };
    state.editedElevator.feature.id = payload.feature.id;
    state.startingLevelId = payload.feature.properties.levelId;
    state.startingJunctionId = payload.feature.id;
    state.chosenLevels = [];
    state.isNew = true;
  },

  editElevator(state, payload) {
    state.editedElevator = payload.junctions.find(
      (junction) => junction.id === payload.currentStopId
    );
    state.chosenLevels = [];

    for (const junction of payload.junctions) {
      state.chosenLevels.push(junction.levelId);
    }
    state.isNew = false;
  },

  setChosenLevels(state, payload) {
    state.chosenLevels = payload;
  },

  reset(state) {
    state.editedElevator = null;
    state.chosenLevels = [];
    state.startingJunctionId = null;
    state.startingLevelId = null;
    state.isNew = false;
  },

  updateField
};

const getters = {
  editedElevator: (state) => state.editedElevator,
  isNew: (state) => state.isNew,
  chosenLevels: (state) => state.chosenLevels,
  moveType: (state) => state.moveType,
  lastMoveWasSuccessful: (state) => state.lastMoveWasSuccessful,
  getField
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
